import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'

export type NavContextType = {
  isBrowser: boolean
  location: string
  setLocation: (location: string) => void
}

export const NavContext = createContext<NavContextType>({
  isBrowser: true,
  location: '/',
  setLocation: () => {},
})

export const NavProviderBrowser = ({
  children,
}: PropsWithChildren): ReactElement => {
  const location = window.location.pathname
  const setLocation = useCallback((location: string) => {
    window.location.href = location
  }, [])

  const value = useMemo(
    () => ({ isBrowser: true, location, setLocation }),
    [location, setLocation],
  )

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}

export const NavProviderElectron = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [location, setLocation] = useState<string>('/')

  const value = useMemo(
    () => ({ isBrowser: false, location, setLocation }),
    [location, setLocation],
  )

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
