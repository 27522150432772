import { useQuery } from 'convex/react'
import { createContext, PropsWithChildren, ReactElement } from 'react'

import CalDataFrozen from './CalDataFrozen'
import { api } from './convex/_generated/api'
import { Doc, Id } from './convex/_generated/dataModel'
import { VersionStub } from './types'

type VersionContextType = {
  calId: Id<'cals'>
  calSubs: Doc<'subscriptions'>[]
  recipients: Doc<'recipients'>[]
  versionStubs: VersionStub[]
  activeVersionId: Id<'calVersions'> | null
  activeVersionStub: VersionStub | null
  parentVersionStub: VersionStub | null
  parentVersionData: CalDataFrozen | null
}

export const VersionContext = createContext<VersionContextType>(
  {} as unknown as VersionContextType,
)

const VersionProvider = ({
  calId,
  calVersionId,
  children,
}: PropsWithChildren<{
  calId: Id<'cals'>
  calVersionId?: Id<'calVersions'>
}>): ReactElement => {
  const versionStubs = useQuery(api.cals.listCalVersionStubs, { calId }) ?? []

  const activeVersionId =
    calVersionId ??
    (versionStubs.length
      ? versionStubs.reduce((acc, version) =>
          version.lastUpdated > acc.lastUpdated ? version : acc,
        )._id
      : null)

  const activeVersionStub = versionStubs?.find(
    (version) => version._id === activeVersionId,
  )

  const parentVersionStub = versionStubs?.find(
    (version) => version._id === activeVersionStub?.parentVersionId,
  )

  const parentVersionDataJson = useQuery(api.cals.getCalDataConvexJson, {
    calVersionId: parentVersionStub?._id,
  })

  const parentVersionData = parentVersionDataJson
    ? CalDataFrozen.fromConvexData(parentVersionDataJson)
    : null

  const calSubs = useQuery(api.subscriptions.listForCal, { calId })

  const recipients = useQuery(api.recipients.listForCal, { calId })

  return (
    <VersionContext.Provider
      value={{
        calId,
        calSubs: calSubs ?? [],
        recipients: recipients ?? [],
        versionStubs: versionStubs ?? [],
        activeVersionId: activeVersionId ?? null,
        activeVersionStub: activeVersionStub ?? null,
        parentVersionStub: parentVersionStub ?? null,
        parentVersionData: parentVersionData ?? null,
      }}
    >
      {children}
    </VersionContext.Provider>
  )
}

export default VersionProvider
