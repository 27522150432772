import { LocalDate } from '@js-joda/core'

import CalData from '../CalData'
import { CalEvent, CalEventFormat, TempCalEvent } from '../types'
import { replaceEventVariables } from './variables'

export const defaultFormat = (): CalEventFormat => ({
  display: 'banner',
  displayWeeklyDay: undefined,
})

export const clearFormat = (): {
  [K in Required<keyof CalEventFormat>]: undefined
} => {
  return {
    display: undefined,
    displayWeeklyDay: undefined,
  }
}

export const getLongestLabel = (
  data: CalData,
  event: CalEvent | TempCalEvent,
  newLabel: string,
): string => {
  const endDate = event.endDate ?? LocalDate.now()
  const emptyMap = new Map<string, number>()

  return replaceEventVariables(
    newLabel,
    event.durationDays ?? 1,
    event.durationWeeks ?? 1,
    event.dayCountersUp ?? emptyMap,
    event.dayCountersDown ?? emptyMap,
    event.weekCountersUp ?? emptyMap,
    event.weekCountersDown ?? emptyMap,
    endDate.toString(),
    data.weekOf(endDate).toString(),
  )
}
