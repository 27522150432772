export const setsAreIdentical = <T>(a: Set<T>, b: Set<T>): boolean => {
  if (a.size !== b.size) return false
  for (const item of a) if (!b.has(item)) return false
  return true
}

export const mapsAreIdentical = <K, V>(a: Map<K, V>, b: Map<K, V>): boolean => {
  if (a.size !== b.size) return false
  for (const [key, value] of a) {
    if (!b.has(key)) return false
    if (b.get(key) !== value) return false
  }
  return true
}
