import classnames from 'classnames'
import { PropsWithChildren, ReactElement } from 'react'

import Wordmark from './icons/Wordmark'
import z from './z'

const TopBarStructure = ({
  topBar,
  main,
  showWordmark,
}: PropsWithChildren<{
  topBar: ReactElement
  main: ReactElement
  showWordmark: boolean
}>): ReactElement => (
  <div className="absolute inset-0">
    <div
      className={classnames(
        'bg-blue-200 fixed top-0 left-0 w-full flex flex-row items-center justify-between px-4 select-none',
      )}
      style={{
        height: 40,
        zIndex: z('topBar'),
      }}
    >
      {showWordmark && <Wordmark />}
      <div className="flex-row gap-2">{topBar}</div>
    </div>

    <div
      className="bg-gray-200 w-full flex-1 overflow-auto select-none group"
      style={{
        paddingTop: 40,
      }}
    >
      {main}
    </div>
  </div>
)

export default TopBarStructure
