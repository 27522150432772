import { DayOfWeek, LocalDate } from '@js-joda/core'

import { CalEvent, CalEventChanges } from '../types'
import { mapsAreIdentical, setsAreIdentical } from './sets'

const booleanKeys = ['transparent'] as const
const stringKeys = [
  'id',
  'label',
  'categoryId',
  'durationUnit',
  'display',
  'textAlign',
] as const
const dateKeys = ['startDate', 'endDate'] as const
const dayOfWeekKeys = ['displayWeeklyDay'] as const
const numberKeys = [
  'firstRow',
  'lastRow',
  'durationDays',
  'durationWeeks',
] as const
const setOfStringKeys = ['skips', 'adds'] as const
const mapOfStringToNumberKeys = [
  'displayBooleans',
  'counterBooleans',
  'dayCountersUp',
  'dayCountersDown',
  'weekCountersUp',
  'weekCountersDown',
] as const

const allKeys = [
  ...booleanKeys,
  ...stringKeys,
  ...dateKeys,
  ...dayOfWeekKeys,
  ...numberKeys,
  ...setOfStringKeys,
  ...mapOfStringToNumberKeys,
] as const

type CheckedKeys =
  | (typeof booleanKeys)[number]
  | (typeof stringKeys)[number]
  | (typeof dateKeys)[number]
  | (typeof dayOfWeekKeys)[number]
  | (typeof numberKeys)[number]
  | (typeof setOfStringKeys)[number]
  | (typeof mapOfStringToNumberKeys)[number]

// Assert that all keys are being checked
type TrueIfKeysMatch = CheckedKeys extends keyof CalEvent
  ? keyof CalEvent extends CheckedKeys
    ? true
    : never
  : never
;((_: TrueIfKeysMatch) => _)(true)

// Asser that all keys are included in allKeys
type TrueIfAllKeysIncluded = keyof CalEvent extends (typeof allKeys)[number]
  ? (typeof allKeys)[number] extends keyof CalEvent
    ? true
    : never
  : never
;((_: TrueIfAllKeysIncluded) => _)(true)

const typeMap = new Map<string, string>()
for (const key of booleanKeys) {
  typeMap.set(key, 'boolean')
}
for (const key of stringKeys) {
  typeMap.set(key, 'string')
}
for (const key of dateKeys) {
  typeMap.set(key, 'LocalDate')
}
for (const key of dayOfWeekKeys) {
  typeMap.set(key, 'DayOfWeek')
}
for (const key of numberKeys) {
  typeMap.set(key, 'number')
}
for (const key of setOfStringKeys) {
  typeMap.set(key, 'Set<string>')
}
for (const key of mapOfStringToNumberKeys) {
  typeMap.set(key, 'Map<string, number>')
}

export const computeChange = <K extends keyof CalEvent>(
  key: K,
  a: CalEvent[K],
  b: CalEvent[K],
): false | [CalEvent[K], CalEvent[K]] => {
  if (a === undefined && b === undefined) return false
  if (a === undefined || b === undefined) return [a, b]

  const type = typeMap.get(key)

  switch (type) {
    case 'boolean':
      if ((a as boolean) === (b as boolean)) return false
      break
    case 'string':
      if ((a as string) === (b as string)) return false
      break
    case 'LocalDate':
      if ((a as LocalDate).equals(b as LocalDate)) return false
      break
    case 'DayOfWeek':
      if ((a as DayOfWeek).equals(b as DayOfWeek)) return false
      break
    case 'number':
      if ((a as number) === (b as number)) return false
      break
    case 'Set<string>':
      if (setsAreIdentical(a as Set<string>, b as Set<string>)) return false
      break
    case 'Map<string, number>':
      if (mapsAreIdentical(a as Map<string, number>, b as Map<string, number>))
        return false
      break
    default:
      return false
  }
  return [a, b]
}

export const computeChanges = (
  oldEvent: CalEvent,
  newEvent: CalEvent,
): CalEventChanges => {
  const changes: {
    [K in keyof CalEvent]?: [CalEvent[keyof CalEvent], CalEvent[keyof CalEvent]]
  } = {}

  for (const key of allKeys) {
    const change = computeChange(key, oldEvent[key], newEvent[key])
    if (change === false) continue
    changes[key] = change
  }

  return changes as CalEventChanges
}

export const applyChange = <K extends keyof CalEvent>(
  newEvent: CalEvent,
  key: K,
  change: [CalEvent[K], CalEvent[K]],
): CalEvent => {
  newEvent[key] = change[1]
  return newEvent
}

export const applyChanges = (
  event: CalEvent,
  changes: CalEventChanges,
): CalEvent => {
  const newEvent = { ...event }
  for (const key of Object.keys(changes) as (keyof CalEvent)[]) {
    const change = changes[key]
    if (change !== undefined) {
      applyChange(newEvent, key, change)
    }
  }
  return newEvent
}

export const revertChange = <K extends keyof CalEvent>(
  oldEvent: CalEvent,
  key: K,
  change: [CalEvent[K], CalEvent[K]],
): CalEvent => {
  oldEvent[key] = change[0]
  return oldEvent
}

export const revertChanges = (
  event: CalEvent,
  changes: CalEventChanges,
): CalEvent => {
  const oldEvent = { ...event }
  for (const key of Object.keys(changes) as (keyof CalEvent)[]) {
    const change = changes[key]
    if (change !== undefined) {
      revertChange(oldEvent, key, change)
    }
  }
  return oldEvent
}
