import { CalEvent } from './types'

class WeekIndex {
  private eventIdsForWeekId = new Map<string, string[]>()

  constructor(events: CalEvent[]) {
    for (const event of events) {
      this.addEvent(event)
    }
  }

  public clone(): WeekIndex {
    const clone = new WeekIndex([])
    for (const [weekId, eventIds] of this.eventIdsForWeekId.entries()) {
      clone.eventIdsForWeekId.set(weekId, [...eventIds])
    }
    return clone
  }

  public getEventIds(weekId: string): string[] {
    const eventIds = this.eventIdsForWeekId.get(weekId)
    if (!eventIds) return []
    return [...eventIds]
  }

  public addEvent(event: CalEvent): void {
    for (const [weekId] of event.displayBooleans) {
      let eventIdsForWeekId = this.eventIdsForWeekId.get(weekId)
      if (!eventIdsForWeekId) {
        eventIdsForWeekId = []
      }
      if (eventIdsForWeekId.includes(event.id)) {
        continue
      }
      eventIdsForWeekId.push(event.id)
      this.eventIdsForWeekId.set(weekId, eventIdsForWeekId)
    }
  }

  public removeEvent(event: CalEvent): void {
    for (const [weekId] of event.displayBooleans) {
      let eventIdsForWeekId = this.eventIdsForWeekId.get(weekId)
      if (!eventIdsForWeekId) continue
      eventIdsForWeekId = eventIdsForWeekId.filter((id) => id !== event.id)
      this.eventIdsForWeekId.set(weekId, eventIdsForWeekId)
    }
  }

  public updateEvent(oldEvent: CalEvent, newEvent: CalEvent): void {
    this.removeEvent(oldEvent)
    this.addEvent(newEvent)
  }
}

export default WeekIndex
