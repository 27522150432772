import { useAuth0 } from '@auth0/auth0-react'
import { AuthTokenFetcher, ConvexProviderWithAuth } from 'convex/react'
import { ReactElement, ReactNode, useCallback, useMemo } from 'react'

import { loginLogoutOptions } from './defaults-auth'

type IConvexReactClient = {
  setAuth(fetchToken: AuthTokenFetcher): void
  clearAuth(): void
}

export function ConvexProvider({
  children,
  client,
}: {
  children: ReactNode
  client: IConvexReactClient
}): ReactElement {
  return (
    <ConvexProviderWithAuth client={client} useAuth={useAuthFromAuth0}>
      {children}
    </ConvexProviderWithAuth>
  )
}

function useAuthFromAuth0(): {
  isLoading: boolean
  isAuthenticated: boolean
  fetchAccessToken: ({
    forceRefreshToken,
  }: {
    forceRefreshToken: boolean
  }) => Promise<string | null>
} {
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0()
  const fetchAccessToken = useCallback(
    async ({ forceRefreshToken }: { forceRefreshToken: boolean }) => {
      try {
        const response = await getAccessTokenSilently({
          detailedResponse: true,
          cacheMode: forceRefreshToken ? 'off' : 'on',
        })
        sessionStorage.setItem('id_token', response.id_token)
        return response.id_token as string
      } catch (error) {
        if (forceRefreshToken) {
          sessionStorage.setItem('logged_out_path', window.location.pathname)
          logout({
            ...loginLogoutOptions,
            logoutParams: {
              returnTo: `${window.location.origin}/login`,
            },
          })
        }
        return null
      }
    },
    [getAccessTokenSilently, logout],
  )
  return useMemo(
    () => ({ isLoading, isAuthenticated, fetchAccessToken }),
    [isLoading, isAuthenticated, fetchAccessToken],
  )
}
