import { ReactElement } from 'react'

import TopBarStructure from './TopBarStructure'

const CalLoading = (): ReactElement => {
  return (
    <TopBarStructure
      topBar={<></>}
      main={
        <div className="w-full h-full flex-1 justify-center items-center">
          <div className="text-2xl font-bold"></div>
        </div>
      }
      showWordmark={false}
    />
  )
}

export default CalLoading
