import classnames from 'classnames'
import { forwardRef, HTMLProps, ReactElement } from 'react'

import Chevron, { ChevronProps } from './icons/Chevron'

export type ButtonProps = {
  label: string
  onMouseDown?: () => void
  onClick?: () => void
  disabled?: boolean
  className?: string
  chevron?: ChevronProps['direction']
  theme:
    | 'white'
    | 'red'
    | 'blue'
    | 'white-border'
    | 'red-border'
    | 'blue-border'
  link?: string
}

const Button = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & ButtonProps
>(function Button(
  {
    label,
    onMouseDown,
    onClick,
    disabled,
    className,
    chevron,
    theme,
    link,
    ...rest
  }: ButtonProps,
  ref,
): ReactElement {
  return (
    <div
      ref={ref}
      className={classnames(
        'inline-flex flex-row items-center justify-center px-4 py-0.5',
        'rounded-md focus:outline-none transition ease-in-out duration-150',
        'whitespace-nowrap select-none',
        'text-sm leading-6 font-medium',
        'border',
        {
          'opacity-50 cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
          'bg-white hover:bg-gray-100':
            theme === 'white' || theme === 'white-border',
          'bg-red-200 hover:bg-red-300 text-gray-900':
            theme === 'red' || theme === 'red-border',
          'bg-blue-700 hover:bg-blue-600 text-white': theme === 'blue',
          'border-gray-400':
            theme === 'white-border' ||
            theme === 'red-border' ||
            theme === 'blue-border',
          'border-transparent':
            theme === 'white' || theme === 'red' || theme === 'blue',
        },
        className,
      )}
      onMouseDown={disabled ? undefined : onMouseDown}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {link ? (
        <a href={link} className="w-full h-full select-none">
          {label}
        </a>
      ) : (
        label
      )}

      {label === '' && <>&nbsp;</>}

      {chevron && (
        <Chevron
          className="ml-1 mr-[-0.25rem] pointer-events-none w-4 h-4"
          direction={chevron}
        />
      )}
    </div>
  )
})

export default Button
