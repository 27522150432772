import CalData from '../CalData'
import {
  CalEvent,
  CalEventField,
  CalEventFormat,
  CalEventSpan,
  Drag,
  Placement,
} from '../types'
import { computeDraggedEventFormat, computeDraggedEventLocation } from './dates'
import {
  applyOffsetToSpan,
  applyOffsetToSpanAndIncludeTargetDate,
  computeOffset,
} from './offsets'
import { removeSkipsPreventingMoveToDate } from './skips'
import { trimSpan } from './spans'

export const computeFollowerPlacement = (
  data: CalData,
  followerEvent: CalEvent,
  leaderEvent: CalEvent,
  leaderPlacement: Placement,
  drag: NonNullable<Drag>,
  trimSkippedEnds: boolean,
): Placement => {
  const followerIsAfterLeader = followerEvent.startDate.isAfter(
    leaderEvent.endDate,
  )

  let followerField = {
    skips: followerEvent.skips,
    adds: followerEvent.adds,
  }

  if (
    followerEvent.durationDays === 1 &&
    leaderEvent.durationDays === 1 &&
    followerEvent.startDate.equals(leaderEvent.startDate)
  ) {
    followerField = removeSkipsPreventingMoveToDate(
      data,
      followerField,
      drag.mousePosition.day,
      followerEvent as CalEventFormat,
    )
  }

  const emptyField: CalEventField = {
    skips: new Set(),
    adds: new Set(),
  }

  let span: CalEventSpan
  if (followerIsAfterLeader) {
    const offset = computeOffset(
      data,
      emptyField,
      leaderEvent.endDate,
      leaderPlacement.span.endDate,
    )
    span = applyOffsetToSpan(data, followerField, followerEvent, offset)
  } else {
    const offset = computeOffset(
      data,
      emptyField,
      leaderEvent.startDate,
      leaderPlacement.span.startDate,
    )
    span = applyOffsetToSpan(data, followerField, followerEvent, offset)
  }

  if (trimSkippedEnds) {
    span = trimSpan(data, span, followerField)
  }

  const location = computeDraggedEventLocation(data, followerEvent, drag)

  const format = computeDraggedEventFormat(data, followerEvent, drag)

  return {
    span,
    location,
    format,
    field: followerField,
  }
}

export const computeLeaderPlacement = (
  data: CalData,
  event: CalEvent,
  drag: NonNullable<Drag>,
  trimSkippedEnds: boolean,
): Placement => {
  let field = {
    skips: event.skips,
    adds: event.adds,
  }

  if (event.durationDays === 1) {
    field = removeSkipsPreventingMoveToDate(
      data,
      field,
      drag.mousePosition.day,
      event as CalEventFormat,
    )
  }

  const offset = computeOffset(
    data,
    field,
    drag.mouseDown.day,
    drag.mousePosition.day,
  )

  let span
  if (event.display === 'weekly') {
    span = applyOffsetToSpan(data, field, event, {
      signedDurationDays: 1,
      signedDurationWeeks: offset.signedDurationWeeks,
    })
  } else {
    span = applyOffsetToSpanAndIncludeTargetDate(
      data,
      field,
      event,
      offset,
      drag.mousePosition.day,
    )
  }

  if (trimSkippedEnds) {
    span = trimSpan(data, span, field)
  }

  const location = computeDraggedEventLocation(data, event, drag)

  const format = computeDraggedEventFormat(data, event, drag)

  return {
    span,
    field,
    location,
    format,
  }
}
