import { createClient, LiveMap } from '@liveblocks/client'
import { CustomAuthenticationResult } from '@liveblocks/core/dist'
import { createRoomContext } from '@liveblocks/react'
import * as Sentry from '@sentry/react'
import { jwtDecode } from 'jwt-decode'

import { CalCategory, CalEvent, CalMetadata, Serialized } from './types'

const tokenHasExpired = (token: string): boolean => {
  const decodedToken = jwtDecode(token)
  return !decodedToken?.exp || decodedToken.exp * 1000 < Date.now()
}

const loginWithToken = async (
  roomId: string,
  token: string,
): Promise<CustomAuthenticationResult> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CONVEX_API_URL}/liveblocks/auth?${roomId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    const result = response.json()
    return result
  } catch (e) {
    console.error(e)
    Sentry.withScope((scope) => {
      scope.setExtras({
        roomId,
        token,
      })
      Sentry.captureException(e)
    })
    return {
      error: e instanceof Error ? e.toString() : 'unknown',
      reason: 'loginWithToken threw',
    }
  }
}

const client = createClient({
  authEndpoint: async (roomId): Promise<CustomAuthenticationResult> => {
    if (roomId === undefined) {
      return {
        error: 'forbidden',
        reason: 'no roomId',
      }
    }

    const token = sessionStorage.getItem('id_token')
    if (token === null || tokenHasExpired(token)) {
      return new Promise((resolve) => {
        let interval: NodeJS.Timeout | undefined
        interval = setInterval(() => {
          const newToken = sessionStorage.getItem('id_token')
          if (newToken !== null && !tokenHasExpired(newToken)) {
            clearInterval(interval)
            sessionStorage.removeItem('id_token')
            loginWithToken(roomId, newToken).then(resolve)
          }
        }, 100)
      })
    } else {
      return loginWithToken(roomId, token)
    }
  },
  throttle: 100,
})

// Presence represents the properties that exist on every user in the Room
// and that will automatically be kept in sync. Accessible through the
// `user.presence` property. Must be JSON-serializable.
export type Presence = {
  // cursor: { x: number, y: number } | null,
  // ...
}

// Optionally, Storage represents the shared document that persists in the
// Room, even after all users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
export type Storage = {
  events: LiveMap<string, Serialized<CalEvent>>
  metadata: LiveMap<keyof CalMetadata, string>
  categories: LiveMap<string, CalCategory>
}

// Optionally, UserMeta represents static/readonly metadata on each user, as
// provided by your own custom auth back end (if used). Useful for data that
// will not change during a session, like a user's name or avatar.
export type UserMeta = {
  // id?: string,  // Accessible through `user.id`
  // info?: Json,  // Accessible through `user.info`
}

// Optionally, the type of custom events broadcast and listened to in this
// room. Use a union for multiple events. Must be JSON-serializable.
export type RoomEvent = {
  // type: "NOTIFICATION",
  // ...
}

export const {
  RoomProvider,
  useRoom,
  useMyPresence,
  useUpdateMyPresence,
  useSelf,
  useOthers,
  useOthersMapped,
  useOthersConnectionIds,
  useOther,
  useBroadcastEvent,
  useEventListener,
  useErrorListener,
  useStorage,
  useObject,
  useMap,
  useList,
  useBatch,
  useHistory,
  useUndo,
  useRedo,
  useCanUndo,
  useCanRedo,
  useMutation,
  useStatus,
  useLostConnectionListener,
} = createRoomContext<Presence, Storage, UserMeta, RoomEvent>(client)
