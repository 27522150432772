import * as Sentry from '@sentry/react'
import { ReactElement, ReactNode } from 'react'

Sentry.init({
  release: process.env.REACT_APP_SHA as string,
  environment:
    process.env.NODE_ENV === 'development'
      ? 'local'
      : window.location.origin === 'https://dev.postcal.io'
      ? 'dev'
      : 'prod',
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        process.env.REACT_APP_CONVEX_URL as string,
        process.env.REACT_APP_CONVEX_API_URL as string,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const SentryFallback = (): ReactElement => (
  <div>
    <h1>Something went wrong.</h1>
    <h2>
      This error has been reported to PostCal. Please refresh this page to
      continue.
    </h2>
  </div>
)

const SentryGate = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Sentry.ErrorBoundary fallback={SentryFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default SentryGate
