import CalData from '../CalData'
import { CalEventField, CalEventSpan } from '../types'
import {
  dateOrEarliestLaterUnskipped,
  dateOrLatestEarlierUnskipped,
} from './skips'

export const boundSpan = (
  { startDate, endDate }: CalEventSpan,
  boundary: 'startDate' | 'endDate',
): CalEventSpan => {
  if (boundary === 'startDate') {
    if (endDate.isBefore(startDate)) {
      return { startDate: startDate, endDate: startDate }
    }
  } else if (boundary === 'endDate') {
    if (startDate.isAfter(endDate)) {
      return { startDate: endDate, endDate: endDate }
    }
  }
  return { startDate, endDate }
}

export const trimSpan = (
  data: CalData,
  { startDate, endDate }: CalEventSpan,
  field: CalEventField,
): CalEventSpan => {
  return {
    startDate: dateOrEarliestLaterUnskipped(data, field, startDate),
    endDate: dateOrLatestEarlierUnskipped(data, field, endDate),
  }
}
