import { RichTextVariableName } from '../types'

export const variableLabels: Record<RichTextVariableName, string> = {
  DayCount: 'Day Count',
  DayCountdown: 'Day Countdown',
  DaysTotal: 'Days Total',
  WeekCount: 'Week Count',
  WeekCountdown: 'Week Countdown',
  WeeksTotal: 'Weeks Total',
}

export const replaceEventVariables = (
  label: string,
  durationDays: number,
  durationWeeks: number,
  dayCountersUp: Map<string, number>,
  dayCountersDown: Map<string, number>,
  weekCountersUp: Map<string, number>,
  weekCountersDown: Map<string, number>,
  dayId?: string,
  weekId?: string,
): string => {
  const dayCount = dayCountersUp.get(dayId ?? '')?.toString() ?? '#'
  const dayCountDown = dayCountersDown.get(dayId ?? '')?.toString() ?? '#'
  const weekCount = weekCountersUp.get(weekId ?? '')?.toString() ?? '#'
  const weekCountDown = weekCountersDown.get(weekId ?? '')?.toString() ?? '#'

  return label
    .replace(/<variable name="DayCount"><\/variable>/g, dayCount)
    .replace(/<variable name="DayCountdown"><\/variable>/g, dayCountDown)
    .replace(
      /<variable name="DaysTotal"><\/variable>/g,
      durationDays.toString(),
    )
    .replace(/<variable name="WeekCount"><\/variable>/g, weekCount)
    .replace(/<variable name="WeekCountdown"><\/variable>/g, weekCountDown)
    .replace(
      /<variable name="WeeksTotal"><\/variable>/g,
      durationWeeks.toString(),
    )
}

// Note: Call replaceEventVariables before calling this function
export const getPlaintextEventLabel = (label: string): string => {
  return label
    .replace(/<p>|<\/p>/g, '')
    .replace('<strong>|</strong>', '')
    .replace('<em>|</em>', '')
    .replace('<s>|</s>', '')
    .replace('<u>|</u>', '')
}
