import { ChronoUnit, LocalDate } from '@js-joda/core'

import CalData from '../CalData'
import { CalEventField, CalEventSpan, Offset } from '../types'
import { countSetBits, weekEnding, weekStarting } from './bits'
import { getUnskippedDaysForWeek } from './skips'

/* IMPORTANT: Signed duration is NOT a simple arithmetic offset!
  When using it as an offset, think of a timespan extending from the
   source date to the target date.
  Signed duration represents the size of that timespan,
   which is why a signed duration of 1 means *no change*.

  +1 = no change
  +2 = 1 day/week forward
  +3 = 2 days/weeks forward
   0 = undefined
  -1 = undefined
  -2 = 1 day/week backward
  -3 = 2 days/weeks backward

  This can be confusing, but greatly simplifies the bitwise math.
 */

export const computeOffset = (
  data: CalData,
  { skips, adds }: CalEventField,
  sourceDate: LocalDate,
  targetDate: LocalDate,
): Offset => {
  const mouseDownWeek = data.weekOf(sourceDate)
  const mousePositionWeek = data.weekOf(targetDate)

  const weekDirection = targetDate.isBefore(sourceDate) ? 'backward' : 'forward'

  const firstWeek =
    weekDirection === 'backward' ? mousePositionWeek : mouseDownWeek
  const lastWeek =
    weekDirection === 'backward' ? mouseDownWeek : mousePositionWeek

  let weekOffset = 0
  for (
    let week = firstWeek;
    week.isBefore(lastWeek);
    week = week.plusWeeks(1)
  ) {
    weekOffset++
  }
  weekOffset *= weekDirection === 'backward' ? -1 : 1

  const unskippedDays = getUnskippedDaysForWeek(
    data,
    mousePositionWeek,
    skips,
    adds,
  )
  const shiftedSourceDate = sourceDate.plusWeeks(weekOffset)
  const dayDirection = targetDate.isBefore(shiftedSourceDate)
    ? 'backward'
    : 'forward'
  const offsetDays =
    dayDirection === 'forward'
      ? weekStarting(data, shiftedSourceDate, mousePositionWeek) &
        weekEnding(data, targetDate, mousePositionWeek)
      : weekStarting(data, targetDate, mousePositionWeek) &
        weekEnding(data, shiftedSourceDate, mousePositionWeek)

  const unskippedDaysInOffsetThisWeek = unskippedDays & offsetDays
  const signedDurationDays =
    countSetBits(unskippedDaysInOffsetThisWeek) *
    (dayDirection === 'backward' ? -1 : 1)

  return {
    signedDurationWeeks: weekOffset >= 0 ? weekOffset + 1 : weekOffset - 1,
    signedDurationDays,
  }
}

export const applyOffset = (
  data: CalData,
  { skips, adds }: CalEventField,
  sourceDate: LocalDate,
  offset: Offset,
): LocalDate => {
  const { signedDurationDays, signedDurationWeeks } = offset

  const dayDirection = signedDurationDays >= 0 ? 'forward' : 'backward'
  const weekDirection = signedDurationWeeks >= 0 ? 'forward' : 'backward'

  let signedDurationAbsWeeks = Math.abs(signedDurationWeeks)
  let signedDurationAbsDays = Math.abs(signedDurationDays)

  let weekStartDate = data.weekOf(sourceDate)
  let weekUnskippedDays: number = 0

  weekUnskippedDays = getUnskippedDaysForWeek(data, weekStartDate, skips, adds)

  while (signedDurationAbsWeeks > 1) {
    if (weekUnskippedDays > 0) {
      signedDurationAbsWeeks--
    }

    weekStartDate =
      weekDirection === 'forward'
        ? weekStartDate.plusWeeks(1)
        : weekStartDate.minusWeeks(1)

    weekUnskippedDays = getUnskippedDaysForWeek(
      data,
      weekStartDate,
      skips,
      adds,
    )
  }

  const offsetStartDate = weekStartDate.plusDays(
    data.weekOf(sourceDate).until(sourceDate, ChronoUnit.DAYS),
  )

  while (signedDurationAbsDays > 0) {
    const weekRemainingDays =
      dayDirection === 'forward'
        ? weekStarting(data, offsetStartDate, weekStartDate)
        : weekEnding(data, offsetStartDate, weekStartDate)
    const weekEligibleDays = weekUnskippedDays & weekRemainingDays

    const weekEligibleDaysCount = countSetBits(weekEligibleDays)

    if (signedDurationAbsDays > weekEligibleDaysCount) {
      signedDurationAbsDays -= weekEligibleDaysCount

      weekStartDate =
        dayDirection === 'forward'
          ? weekStartDate.plusWeeks(1)
          : weekStartDate.minusWeeks(1)

      weekUnskippedDays = getUnskippedDaysForWeek(
        data,
        weekStartDate,
        skips,
        adds,
      )
    } else {
      for (const dayIndex of dayDirection === 'forward'
        ? [0, 1, 2, 3, 4, 5, 6]
        : [6, 5, 4, 3, 2, 1, 0]) {
        if (weekEligibleDays & (1 << (6 - dayIndex))) {
          signedDurationAbsDays--
          if (signedDurationAbsDays === 0) {
            return weekStartDate.plusDays(dayIndex)
          }
        }
      }
    }
  }
  return sourceDate
}

export const applyOffsetToSpan = (
  data: CalData,
  field: CalEventField,
  span: CalEventSpan,
  offset: Offset,
): CalEventSpan => ({
  startDate: applyOffset(data, field, span.startDate, offset),
  endDate: applyOffset(data, field, span.endDate, offset),
})

export const adjustSpanTowardTargetDate = (
  data: CalData,
  field: CalEventField,
  span: CalEventSpan,
  targetDate: LocalDate,
): CalEventSpan => {
  let signedDurationDays = null
  let newSpan = span
  if (targetDate.isBefore(newSpan.startDate)) {
    while (targetDate.isBefore(newSpan.startDate)) {
      if (signedDurationDays === null) {
        signedDurationDays = -2
      } else {
        signedDurationDays -= 1
      }
      const offset = { signedDurationWeeks: 1, signedDurationDays }
      newSpan = applyOffsetToSpan(data, field, span, offset)
    }
    return newSpan
  }

  while (targetDate.isAfter(newSpan.endDate)) {
    if (signedDurationDays === null) {
      signedDurationDays = 2
    } else {
      signedDurationDays += 1
    }
    const offset = { signedDurationWeeks: 1, signedDurationDays }
    newSpan = applyOffsetToSpan(data, field, span, offset)
  }
  return newSpan
}

export const applyOffsetToSpanAndIncludeTargetDate = (
  data: CalData,
  field: CalEventField,
  span: CalEventSpan,
  offset: Offset,
  targetDate: LocalDate,
): CalEventSpan => {
  let newSpan = applyOffsetToSpan(data, field, span, offset)
  return adjustSpanTowardTargetDate(data, field, newSpan, targetDate)
}
