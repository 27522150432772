import { CalCategory, CalMetadata } from './types'

export const emptyCalMetadata: CalMetadata = {
  title: '',
  headerLeft: '',
  headerRight: '',
  footerLeft: '',
  footerMiddle: '',
  footerRight: '',
}

export const defaultCalMetadata: CalMetadata = {
  title: 'Title of Project',
  headerLeft: 'Left Header',
  headerRight: 'Right Header',
  footerLeft: 'Left Footer',
  footerMiddle: 'Mid Footer',
  footerRight: 'Right Footer',
}

export const initialCalMetadata: Partial<CalMetadata> = {
  title: 'Title of Project',
  footerRight: 'Made with PostCal.io',
}

export const defaultDefaultCategory: CalCategory = {
  id: 'default',
  name: 'Misc',
  bannerFill: '#dbeafe',
  bannerStroke: '#60a5fa',
  bannerText: 'rgba(0, 0, 0, 0.87)',
  labelText: '#1d4ed8',
}

export const defaultHolidayCategory: CalCategory = {
  id: 'holiday',
  name: 'Holiday',
  bannerFill: '#f4f4f5',
  bannerStroke: '#a1a1aa',
  bannerText: 'rgba(0, 0, 0, 0.87)',
  labelText: '#3f3f46',
}
