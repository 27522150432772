import { useAuth0 } from '@auth0/auth0-react'
import { useConvexAuth, useMutation } from 'convex/react'
import { useEffect, useState } from 'react'

import { api } from '../_generated/api'
import { Id } from '../_generated/dataModel'

export default function useStoreUserEffect(inviteToken?: string): {
  convexUserId: Id<'users'> | null
  convexUserName: string | null
} | null {
  const { isAuthenticated } = useConvexAuth()
  const { user } = useAuth0()
  // When this state is set we know the server
  // has stored the user.
  const [userId, setUserId] = useState<Id<'users'> | null>(null)
  const [userName, setUserName] = useState<string | null>(null)
  const storeUser = useMutation(api.users.store)
  // Call the `storeUser` mutation function to store
  // the current user in the `users` table and return the `Id` value.
  useEffect(() => {
    // If the user is not logged in don't do anything
    if (!isAuthenticated) {
      return
    }
    // Store the user in the database.
    // Recall that `storeUser` gets the user information via the `auth`
    // object on the server. You don't need to pass anything manually here.
    ;(async function createUser(): Promise<void> {
      const { id, name } = await storeUser({
        inviteToken,
      })
      setUserId(id)
      setUserName(name)
      if (inviteToken) {
        window.history.replaceState({}, '', window.location.pathname)
      }
    })()
    return () => setUserId(null)
    // Make sure the effect reruns if the user logs in with
    // a different identity
  }, [inviteToken, isAuthenticated, storeUser, user?.id])
  return { convexUserId: userId, convexUserName: userName }
}
