import { DateTimeFormatter, DayOfWeek, LocalDate } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'

import CalData from '../CalData'
import {
  CalEvent,
  CalEventDuration,
  CalEventField,
  CalEventFormat,
  CalEventLocation,
  CalEventMaps,
  CalEventSpan,
  Drag,
} from '../types'
import { weekEnding, weekStarting } from './bits'
import { applyOffset, computeOffset } from './offsets'
import {
  dateOrEarliestLaterUnskipped,
  dateOrLatestEarlierUnskipped,
  getUnskippedDaysForWeek,
} from './skips'
import { boundSpan, trimSpan } from './spans'

export const fieldDoesNotAffectSpan = (
  data: CalData,
  span: CalEventSpan,
  field: CalEventField,
): boolean => {
  const newSpan = trimSpan(data, span, field)
  return (
    newSpan.startDate.isEqual(span.startDate) &&
    newSpan.endDate.isEqual(span.endDate)
  )
}

export const computeDurationAndMaps = (
  data: CalData,
  { startDate, endDate }: CalEventSpan,
  { skips, adds }: CalEventField,
  { display, displayWeeklyDay }: CalEventFormat,
): CalEventDuration & CalEventMaps => {
  const weekStartDates = data.listWeekStartDatesForSpan({
    startDate,
    endDate,
  })

  const counterBooleans: Map<string, number> = new Map()
  const displayBooleans: Map<string, number> = new Map()

  for (let weekIndex = 0; weekIndex < weekStartDates.length; weekIndex++) {
    const weekStartDate = weekStartDates[weekIndex]
    const weekId = weekStartDate.toString()

    const weekActiveDays =
      weekStarting(data, startDate, weekStartDate) &
      weekEnding(data, endDate, weekStartDate)

    const unskippedDays = getUnskippedDaysForWeek(
      data,
      weekStartDate,
      skips,
      adds,
    )

    const counterWeek = weekActiveDays & unskippedDays

    counterBooleans.set(weekId, counterWeek)

    if (display === 'weekly' && displayWeeklyDay !== undefined) {
      if (counterWeek > 0) {
        const dayIndex =
          (displayWeeklyDay.value() - data.weekStartsOn.value() + 7) % 7
        displayBooleans.set(weekId, 1 << (6 - dayIndex))
      } else {
        displayBooleans.set(weekId, 0)
      }
    } else {
      displayBooleans.set(weekId, counterWeek)
    }
  }

  const dayCounters = computeDayCounters(counterBooleans)
  const weekCounters = computeWeekCounters(counterBooleans)

  const durationDays = Array.from(dayCounters.dayCountersDown.values())[0] ?? 0
  const durationWeeks =
    Array.from(weekCounters.weekCountersDown.values())[0] ?? 0

  return {
    durationDays,
    durationWeeks,
    displayBooleans,
    counterBooleans,
    ...dayCounters,
    ...weekCounters,
  }
}

export const computeDraggedEventLocation = (
  data: CalData,
  oldLocation: CalEventLocation,
  drag: NonNullable<Drag>,
): CalEventLocation => {
  const weekId = data.weekOf(drag.mousePosition.day).toString()
  const rowCount = data.getWeekRowCount(weekId)

  const rowDiff = drag.mousePosition.rowIndex - drag.mouseDown.rowIndex

  let newStartRow = oldLocation.firstRow + rowDiff
  let newEndRow = oldLocation.lastRow + rowDiff

  if (newStartRow < 0) {
    const adjustment = 0 - newStartRow
    newStartRow += adjustment
    newEndRow += adjustment
  }
  if (newEndRow >= rowCount) {
    const adjustment = rowCount - newEndRow - 1
    newStartRow += adjustment
    newEndRow += adjustment
  }

  return {
    firstRow: newStartRow,
    lastRow: newEndRow,
  }
}

export const computeDraggedEventFormat = (
  data: CalData,
  draggedEvent: CalEvent,
  drag: NonNullable<Drag>,
): CalEventFormat => {
  let newDisplayWeeklyDay = draggedEvent.displayWeeklyDay
  if (draggedEvent.display === 'weekly') {
    const emptyField: CalEventField = {
      skips: new Set(),
      adds: new Set(),
    }
    let dayOffset = computeOffset(
      data,
      emptyField,
      drag.mouseDown.day,
      drag.mousePosition.day,
    )
    const dayOfWeek = draggedEvent.startDate.with(
      draggedEvent.displayWeeklyDay ?? data.weekStartsOn,
    )
    newDisplayWeeklyDay = applyOffset(
      data,
      emptyField,
      dayOfWeek,
      dayOffset,
    ).dayOfWeek()
  }
  return {
    display: draggedEvent.display,
    displayWeeklyDay: newDisplayWeeklyDay,
  }
}

export const computeSpansForNewDurationDays = (
  data: CalData,
  field: CalEventField,
  span: CalEventSpan,
  newDurationDays: number,
): [CalEventSpan, CalEventSpan] => {
  const validStartDate = dateOrEarliestLaterUnskipped(
    data,
    field,
    span.startDate,
  )
  let spanPreservingStartDate = {
    startDate: validStartDate,
    endDate: applyOffset(data, field, validStartDate, {
      signedDurationWeeks: 1,
      signedDurationDays: newDurationDays,
    }),
  }
  spanPreservingStartDate = boundSpan(spanPreservingStartDate, 'startDate')

  const validEndDate = dateOrLatestEarlierUnskipped(data, field, span.endDate)
  let spanPreservingEndDate = {
    startDate: applyOffset(data, field, validEndDate, {
      signedDurationWeeks: 1,
      signedDurationDays: -newDurationDays,
    }),
    endDate: validEndDate,
  }
  spanPreservingEndDate = boundSpan(spanPreservingEndDate, 'endDate')

  return [spanPreservingStartDate, spanPreservingEndDate]
}

export const computeSpansForNewDurationWeeks = (
  data: CalData,
  field: CalEventField,
  span: CalEventSpan,
  newDurationWeeks: number,
): [CalEventSpan, CalEventSpan] => {
  const validStartDate = dateOrEarliestLaterUnskipped(
    data,
    field,
    span.startDate,
  )
  let spanPreservingStartDate = {
    startDate: validStartDate,
    endDate: applyOffset(data, field, validStartDate, {
      signedDurationWeeks: newDurationWeeks,
      signedDurationDays: 1,
    }),
  }
  spanPreservingStartDate = boundSpan(spanPreservingStartDate, 'startDate')

  const validEndDate = dateOrLatestEarlierUnskipped(data, field, span.endDate)
  let spanPreservingEndDate = {
    startDate: applyOffset(data, field, validEndDate, {
      signedDurationWeeks: -newDurationWeeks,
      signedDurationDays: 1,
    }),
    endDate: validEndDate,
  }
  spanPreservingEndDate = boundSpan(spanPreservingEndDate, 'endDate')

  return [spanPreservingStartDate, spanPreservingEndDate]
}

export const changeStartDatePreservingEndDate = (
  data: CalData,
  field: CalEventField,
  oldEndDate: LocalDate,
  newStartDate: LocalDate,
  trimSkippedEnds: boolean,
  forceMatchDayOfWeek?: DayOfWeek,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: newStartDate,
    endDate: oldEndDate,
  }
  newSpan = boundSpan(newSpan, 'endDate')
  if (trimSkippedEnds) {
    newSpan = trimSpan(data, newSpan, field)
  }
  if (forceMatchDayOfWeek !== undefined) {
    newSpan.startDate = data.withDayOfWeek(
      forceMatchDayOfWeek,
      newSpan.startDate,
    )
  }
  return newSpan
}

export const changeEndDatePreservingStartDate = (
  data: CalData,
  field: CalEventField,
  oldStartDate: LocalDate,
  newEndDate: LocalDate,
  trimSkippedEnds: boolean,
  forceMatchDayOfWeek?: DayOfWeek,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: oldStartDate,
    endDate: newEndDate,
  }
  newSpan = boundSpan(newSpan, 'startDate')
  if (trimSkippedEnds) {
    newSpan = trimSpan(data, newSpan, field)
  }
  if (forceMatchDayOfWeek !== undefined) {
    newSpan.endDate = data.withDayOfWeek(forceMatchDayOfWeek, newSpan.endDate)
  }
  return newSpan
}

export const changeStartDatePreservingDurationDays = (
  data: CalData,
  field: CalEventField,
  durationDays: number,
  newStartDate: LocalDate,
  trimSkippedEnds: boolean,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: newStartDate,
    endDate: applyOffset(data, field, newStartDate, {
      signedDurationWeeks: 1,
      signedDurationDays: durationDays,
    }),
  }
  newSpan = boundSpan(newSpan, 'startDate')
  if (trimSkippedEnds) {
    newSpan = trimSpan(data, newSpan, field)
  }
  return newSpan
}

export const changeStartDatePreservingDurationWeeks = (
  data: CalData,
  field: CalEventField,
  durationWeeks: number,
  newStartDate: LocalDate,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: newStartDate,
    endDate: applyOffset(data, field, newStartDate, {
      signedDurationWeeks: durationWeeks,
      signedDurationDays: 1,
    }),
  }
  newSpan = boundSpan(newSpan, 'startDate')
  return newSpan
}

export const changeEndDatePreservingDurationDays = (
  data: CalData,
  field: CalEventField,
  durationDays: number,
  newEndDate: LocalDate,
  trimSkippedEnds: boolean,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: applyOffset(data, field, newEndDate, {
      signedDurationWeeks: 1,
      signedDurationDays: -durationDays,
    }),
    endDate: newEndDate,
  }
  newSpan = boundSpan(newSpan, 'endDate')
  if (trimSkippedEnds) {
    newSpan = trimSpan(data, newSpan, field)
  }
  return newSpan
}

export const changeEndDatePreservingDurationWeeks = (
  data: CalData,
  field: CalEventField,
  durationWeeks: number,
  newEndDate: LocalDate,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: applyOffset(data, field, newEndDate, {
      signedDurationWeeks: -durationWeeks,
      signedDurationDays: 1,
    }),
    endDate: newEndDate,
  }
  newSpan = boundSpan(newSpan, 'endDate')
  return newSpan
}

export const changeFieldPreservingSpan = (
  data: CalData,
  span: CalEventSpan,
  newField: CalEventField,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: span.startDate,
    endDate: span.endDate,
  }
  newSpan = trimSpan(data, newSpan, newField)
  return newSpan
}

export const changeFieldPreservingDurationDays = (
  data: CalData,
  { durationDays }: CalEventDuration,
  span: CalEventSpan,
  newField: CalEventField,
): CalEventSpan => {
  let newSpan: CalEventSpan = {
    startDate: span.startDate,
    endDate: span.endDate,
  }
  newSpan = trimSpan(data, newSpan, newField)
  newSpan = boundSpan(newSpan, 'startDate')
  newSpan.endDate = applyOffset(data, newField, newSpan.startDate, {
    signedDurationWeeks: 1,
    signedDurationDays: durationDays,
  })
  return newSpan
}

export const computeWeekCounters = (
  counterBooleans: Map<string, number>,
): {
  weekCountersUp: Map<string, number>
  weekCountersDown: Map<string, number>
} => {
  const weekIds = Array.from(counterBooleans.keys()).sort()

  const weekCountersUp: Map<string, number> = new Map()

  let weekCount = 1
  for (const weekId of weekIds) {
    const activeDays = counterBooleans.get(weekId)!
    if (activeDays > 0) {
      weekCountersUp.set(weekId, weekCount)
      weekCount++
    }
  }

  const weekCountersDown: Map<string, number> = new Map()
  const keys = Array.from(weekCountersUp.keys())
  for (let i = 0; i < keys.length; i++) {
    weekCountersDown.set(keys[i], keys.length - i)
  }

  return { weekCountersUp, weekCountersDown }
}

export const computeDayCounters = (
  counterBooleans: Map<string, number>,
): {
  dayCountersUp: Map<string, number>
  dayCountersDown: Map<string, number>
} => {
  const weekIds = Array.from(counterBooleans.keys()).sort()

  const dayCountersUp: Map<string, number> = new Map()

  let dayCount = 1
  for (const weekId of weekIds) {
    const week = LocalDate.parse(weekId)
    const activeDays = counterBooleans.get(weekId)!

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      const dayIsActive = !!(activeDays & (1 << (6 - dayIndex)))
      if (dayIsActive) {
        const day = week.plusDays(dayIndex)
        const dayId = day.toString()
        dayCountersUp.set(dayId, dayCount)
        dayCount++
      }
    }
  }

  const dayCountersDown: Map<string, number> = new Map()
  const keys = Array.from(dayCountersUp.keys())
  for (let i = 0; i < keys.length; i++) {
    dayCountersDown.set(keys[i], keys.length - i)
  }

  return { dayCountersUp, dayCountersDown }
}

const monthDayYear = DateTimeFormatter.ofPattern('MMM d, yyyy').withLocale(
  Locale.US,
)
const monthDay = DateTimeFormatter.ofPattern('MMM d').withLocale(Locale.US)
const day = DateTimeFormatter.ofPattern('MMM').withLocale(Locale.US)

export const dateRangeAsString = (event: CalEventSpan): string => {
  // TODO unscheduled events: return empty string

  if (event.startDate.equals(event.endDate)) {
    return event.startDate.format(monthDay)
  }

  if (event.startDate.year() === event.endDate.year()) {
    if (event.startDate.month().equals(event.endDate.month())) {
      return `${event.startDate.format(
        day,
      )} ${event.startDate.dayOfMonth()}-${event.endDate.dayOfMonth()}`
    }
    return `${event.startDate.format(monthDay)} - ${event.endDate.format(
      monthDay,
    )}`
  }

  if (event.startDate.year() + 1 === event.endDate.year()) {
    return `${event.startDate.format(monthDay)} - ${event.endDate.format(
      monthDay,
    )}`
  }

  return `${event.startDate.format(monthDayYear)} - ${event.endDate.format(
    monthDayYear,
  )}`
}
