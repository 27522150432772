const zIndexes = [
  'selectedCell',
  'eventRect',
  'eventLabel',
  'hover',
  'marquee',
  'sidebar',
  'topBar',
  'navigator',
  'projectPicker',
  'versionModal',
  'shareModal',
  'versionDropdown',
  'subscriptionSettingsModal',
  'pdfExportSettings',
  'pdfExportSettingsDatePicker',
  'richTextVariablePicker',
  'categoryPicker',
  'durationSpanPicker',
  'datePicker',
  'customSkipPicker',
  'colorPicker',
  'colorPickerItemLabel',
  'userMenu',
] as const

const z = (element: (typeof zIndexes)[number]): number =>
  1 + zIndexes.indexOf(element)

export default z
