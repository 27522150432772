import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export default function useClearCodeEffect(): void {
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && window.location.search.includes('code=')) {
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [isAuthenticated])
}
