import { DayOfWeek, LocalDate } from '@js-joda/core'

import { CalEvent, CalEventChanges, Serialized } from '../types'

export const serializeCalEventValue = <K extends keyof CalEvent>(
  key: K,
  value: NonNullable<CalEvent[K]>,
): Serialized<CalEvent[K]> => {
  if (value === undefined) return null as Serialized<CalEvent[K]>

  if (key === 'startDate' || key === 'endDate') {
    return value.toString() as Serialized<CalEvent[K]>
  }
  if (key === 'skips' || key === 'adds') {
    return Array.from(value as Set<string>) as Serialized<CalEvent[K]>
  }
  if (
    key === 'displayBooleans' ||
    key === 'counterBooleans' ||
    key === 'dayCountersUp' ||
    key === 'dayCountersDown' ||
    key === 'weekCountersUp' ||
    key === 'weekCountersDown'
  ) {
    return Array.from(value as Map<string, number>) as Serialized<CalEvent[K]>
  }
  if (key === 'displayWeeklyDay') {
    return (value as DayOfWeek).value() as Serialized<CalEvent[K]>
  }
  return value as Serialized<CalEvent[K]>
}

export const deserializeCalEventValue = <K extends keyof CalEvent>(
  key: K,
  value: NonNullable<Serialized<CalEvent[K]>>,
): CalEvent[K] => {
  if (value === null) return undefined as CalEvent[K]

  if (key === 'startDate' || key === 'endDate') {
    return LocalDate.parse(value as string) as CalEvent[K]
  }
  if (key === 'skips' || key === 'adds') {
    return new Set(value as string[]) as CalEvent[K]
  }
  if (
    key === 'displayBooleans' ||
    key === 'counterBooleans' ||
    key === 'dayCountersUp' ||
    key === 'dayCountersDown' ||
    key === 'weekCountersUp' ||
    key === 'weekCountersDown'
  ) {
    return new Map(value as [string, number][]) as CalEvent[K]
  }
  if (key === 'displayWeeklyDay') {
    return DayOfWeek.of(value as number) as CalEvent[K]
  }
  return value as CalEvent[K]
}

export const serializeCalEvent = (event: CalEvent): Serialized<CalEvent> => {
  return {
    ...event,
    startDate: event.startDate.toString(),
    endDate: event.endDate.toString(),
    skips: Array.from(event.skips),
    adds: Array.from(event.adds),
    displayBooleans: Array.from(event.displayBooleans),
    counterBooleans: Array.from(event.counterBooleans),
    dayCountersUp: Array.from(event.dayCountersUp),
    dayCountersDown: Array.from(event.dayCountersDown),
    weekCountersUp: Array.from(event.weekCountersUp),
    weekCountersDown: Array.from(event.weekCountersDown),
    displayWeeklyDay: event.displayWeeklyDay
      ? event.displayWeeklyDay.value()
      : undefined,
  }
}

export const deserializeCalEvent = (event: Serialized<CalEvent>): CalEvent => {
  return {
    ...event,
    startDate: LocalDate.parse(event.startDate),
    endDate: LocalDate.parse(event.endDate),
    skips: new Set(event.skips),
    adds: new Set(event.adds),
    displayBooleans: new Map(event.displayBooleans),
    counterBooleans: new Map(event.counterBooleans),
    dayCountersUp: new Map(event.dayCountersUp),
    dayCountersDown: new Map(event.dayCountersDown),
    weekCountersUp: new Map(event.weekCountersUp),
    weekCountersDown: new Map(event.weekCountersDown),
    displayWeeklyDay: event.displayWeeklyDay
      ? DayOfWeek.of(event.displayWeeklyDay)
      : undefined,
  }
}

export const serializeCalEventChange = <K extends keyof CalEvent>(
  key: K,
  oldValue: CalEvent[K] | undefined,
  newValue: CalEvent[K] | undefined,
): Serialized<CalEvent[K]> => {
  return [
    oldValue === undefined ? null : serializeCalEventValue(key, oldValue),
    newValue === undefined ? null : serializeCalEventValue(key, newValue),
  ] as Serialized<CalEvent[K]>
}

export const serializeCalEventChanges = (
  changes: CalEventChanges,
): Serialized<CalEventChanges> => {
  const serialized = {} as Serialized<CalEventChanges>
  for (const [key, [oldValue, newValue]] of Object.entries(changes) as [
    keyof CalEvent,
    [
      CalEvent[keyof CalEvent] | undefined,
      CalEvent[keyof CalEvent] | undefined,
    ],
  ][]) {
    // @ts-ignore
    serialized[key] = serializeCalEventChange(key, oldValue, newValue)
  }
  return serialized
}

export const deserializeCalEventChange = <K extends keyof CalEvent>(
  key: K,
  oldValue: Serialized<CalEvent[K]> | null,
  newValue: Serialized<CalEvent[K]> | null,
): [CalEvent[K] | undefined, CalEvent[K] | undefined] => {
  return [
    oldValue === null ? undefined : deserializeCalEventValue(key, oldValue),
    newValue === null ? undefined : deserializeCalEventValue(key, newValue),
  ]
}

export const deserializeCalEventChanges = (
  changes: Serialized<CalEventChanges>,
): CalEventChanges => {
  const deserialized = {} as CalEventChanges
  for (const [key, [oldValue, newValue]] of Object.entries(changes) as [
    keyof CalEvent,
    [
      Serialized<CalEvent[keyof CalEvent]> | null,
      Serialized<CalEvent[keyof CalEvent]> | null,
    ],
  ][]) {
    // @ts-ignore
    deserialized[key] = deserializeCalEventChange(key, oldValue, newValue)
  }
  return deserialized
}
