import {
  ChronoUnit,
  DayOfWeek,
  LocalDate,
  TemporalAdjusters,
  YearMonth,
} from '@js-joda/core'

import CalDataFrozen from './CalDataFrozen'
import { weekEnding, weekStarting } from './math/bits'
import {
  changeEndDatePreservingStartDate,
  changeStartDatePreservingEndDate,
  computeDurationAndMaps,
  fieldDoesNotAffectSpan,
} from './math/dates'
import { computeTimeEffects } from './math/effects'
import { defaultFormat } from './math/formats'
import uniqueId from './math/ids'
import { adjustedRowIndex, assignLocations } from './math/locations'
import {
  computeFollowerPlacement,
  computeLeaderPlacement,
} from './math/placements'
import { defaultSkips } from './math/skips'
import { boundSpan, trimSpan } from './math/spans'
import {
  CalCategory,
  CalEvent,
  CalEventField,
  CalEventFormat,
  CalEventLocation,
  CalEventProperties,
  CalEventSkip,
  CalEventSpan,
  CalMetadata,
  Drag,
  Marquee,
} from './types'
import WeekIndex from './WeekIndex'

abstract class CalData {
  public weekStartsOn: DayOfWeek = DayOfWeek.SUNDAY

  public initialWeekRowCount = 8

  protected weekIndex?: WeekIndex

  public selectedEventIds = new Set<string>()

  public treatSelectionAsMulti = false

  public abstract getMetadata: (key: keyof CalMetadata) => string | undefined

  public abstract editMetadata: (
    key: keyof CalMetadata,
  ) => (newValue: string) => void

  public abstract listEvents: () => CalEvent[]

  public abstract getEventById: (id: string | null) => CalEvent | undefined

  public abstract getCategoryById: (id: string) => CalCategory

  public abstract getDefaultCategory: () => CalCategory

  public abstract selectEvent: (
    id: string,
    scrollTo?: boolean,
    asMulti?: boolean,
  ) => void

  public abstract selectEventOnly: (id: string, scrollTo?: boolean) => void

  public abstract unselectEvent: (id: string) => void

  public abstract unselectAllEvents: () => void

  public abstract deleteEvent: (eventId: string) => void

  public abstract editEvents: (changedEvents: CalEvent[]) => void

  public abstract freezeOrClone: () => CalDataFrozen

  public listSelectedEvents = (): CalEvent[] => {
    return [...this.selectedEventIds].map((id) => this.getEventById(id)!)
  }

  public weekOf = (date: LocalDate): LocalDate => {
    return date.with(TemporalAdjusters.previousOrSame(this.weekStartsOn))
  }

  public withDayOfWeek = (
    dayOfWeek: DayOfWeek,
    movingDate: LocalDate,
  ): LocalDate => {
    return this.weekOf(movingDate).with(TemporalAdjusters.nextOrSame(dayOfWeek))
  }

  public withSameDayOfWeek = (
    referenceDate: LocalDate,
    movingDate: LocalDate,
  ): LocalDate => {
    return this.weekOf(movingDate).with(
      TemporalAdjusters.nextOrSame(referenceDate.dayOfWeek()),
    )
  }

  public getWeekEvents = (weekStartDate: LocalDate | string): CalEvent[] => {
    const weekId =
      typeof weekStartDate === 'string'
        ? weekStartDate
        : weekStartDate.toString()
    const eventIds = this.weekIndex?.getEventIds(weekId) ?? []
    const events: CalEvent[] = []
    for (const id of eventIds) {
      const event = this.getEventById(id)
      if (event) events.push(event)
    }
    return events
  }

  public getWeekRowCount = (_weekId: string): number => {
    // return this.weekRowCounts.get(weekId) ?? this.initialWeekRowCount
    return this.initialWeekRowCount
  }

  public listWeekStartDatesForSpan = (span: CalEventSpan): LocalDate[] => {
    const weekStartDates: LocalDate[] = []
    let weekStartDate = this.weekOf(span.startDate)
    while (!weekStartDate.isAfter(span.endDate)) {
      weekStartDates.push(weekStartDate)
      weekStartDate = weekStartDate.plusWeeks(1)
    }
    return weekStartDates
  }

  public getMonthIdsToPrint = (): string[] => {
    let firstMonth = null
    let lastMonth = null

    for (const event of this.listEvents()) {
      if (event.categoryId === 'holiday') continue

      const startMonth = YearMonth.from(event.startDate)
      if (firstMonth === null || startMonth.isBefore(firstMonth)) {
        firstMonth = startMonth
      }

      const endMonth = YearMonth.from(event.endDate)
      if (lastMonth === null || endMonth.isAfter(lastMonth)) {
        lastMonth = endMonth
      }
    }

    if (firstMonth === null || lastMonth === null) {
      return []
    }

    const monthIds = []
    for (
      let month = firstMonth;
      !month.isAfter(lastMonth);
      month = month.plusMonths(1)
    ) {
      monthIds.push(month.toString())
    }
    return monthIds
  }

  public getCellEvent = (
    weekId: string,
    day: LocalDate,
    rowIndex: number,
  ): CalEvent | null => {
    const cellEvents = new Set<CalEvent>()
    const dayIndex = LocalDate.parse(weekId).until(day, ChronoUnit.DAYS)

    const eventIds = this.weekIndex?.getEventIds(weekId) ?? []
    for (const id of eventIds) {
      const event = this.getEventById(id)
      const eventWeek = event?.displayBooleans.get(weekId) ?? 0
      if (
        event &&
        eventWeek & (1 << (6 - dayIndex)) &&
        event.firstRow <= rowIndex &&
        event.lastRow >= rowIndex
      ) {
        cellEvents.add(event)
      }
    }

    if (cellEvents.size === 0) return null

    // TODO cycle through events here, or use right-click to select
    return Array.from(cellEvents)[0]
  }

  public applyHorizontalResizeDrag = (
    draggedEvent: CalEvent,
    drag: NonNullable<Drag>,
    otherSelectedEvents: CalEvent[],
    trimSkippedEnds: boolean,
  ): void => {
    const resizeLeft = (event: CalEvent): void => {
      const newSpan = changeStartDatePreservingEndDate(
        this,
        event,
        event.endDate,
        drag.mousePosition.day,
        trimSkippedEnds,
        event.display === 'weekly' ? event.displayWeeklyDay : undefined,
      )
      this.editEventPlacement(event, newSpan)
    }

    const resizeRight = (event: CalEvent): void => {
      const newSpan = changeEndDatePreservingStartDate(
        this,
        event,
        event.startDate,
        drag.mousePosition.day,
        trimSkippedEnds,
        event.display === 'weekly' ? event.displayWeeklyDay : undefined,
      )
      this.editEventPlacement(event, newSpan)
    }

    if (drag.dragSide === 'left') {
      resizeLeft(draggedEvent)
      for (const otherEvent of otherSelectedEvents) {
        if (otherEvent.startDate.equals(draggedEvent.startDate)) {
          resizeLeft(otherEvent)
        }
      }
    } else {
      resizeRight(draggedEvent)
      for (const otherEvent of otherSelectedEvents) {
        if (otherEvent.endDate.equals(draggedEvent.endDate)) {
          resizeRight(otherEvent)
        }
      }
    }
  }

  public applyVerticalResizeDrag = (
    draggedEvent: CalEvent,
    drag: NonNullable<Drag>,
    otherSelectedEvents: CalEvent[],
  ): void => {
    const mouseDownWeek = this.weekOf(drag.mouseDown.day)
    const mousePositionWeek = this.weekOf(drag.mousePosition.day)

    const mouseIsAboveWeek = mousePositionWeek.isBefore(mouseDownWeek)
    const mouseIsBelowWeek = mousePositionWeek.isAfter(mouseDownWeek)

    const topRow = 0
    const bottomRow = this.getWeekRowCount(mousePositionWeek.toString()) - 1

    const resizeTop = (event: CalEvent): void => {
      const newLocation = {
        firstRow: mouseIsAboveWeek
          ? topRow
          : mouseIsBelowWeek
          ? event.lastRow
          : Math.min(drag.mousePosition.rowIndex, event.lastRow),
        lastRow: event.lastRow,
      }
      this.editEventPlacement(event, undefined, undefined, newLocation)
    }

    const resizeBottom = (event: CalEvent): void => {
      const newLocation = {
        firstRow: event.firstRow,
        lastRow: mouseIsAboveWeek
          ? event.firstRow
          : mouseIsBelowWeek
          ? bottomRow
          : Math.max(drag.mousePosition.rowIndex, event.firstRow),
      }
      this.editEventPlacement(event, undefined, undefined, newLocation)
    }

    if (drag.dragSide === 'top') {
      resizeTop(draggedEvent)
      for (const otherEvent of otherSelectedEvents) {
        if (otherEvent.firstRow === draggedEvent.firstRow) {
          resizeTop(otherEvent)
        }
      }
    } else {
      resizeBottom(draggedEvent)
      for (const otherEvent of otherSelectedEvents) {
        if (otherEvent.lastRow === draggedEvent.lastRow) {
          resizeBottom(otherEvent)
        }
      }
    }
  }

  public applyNonResizingMoveDrag = (
    leaderEvent: CalEvent,
    drag: NonNullable<Drag>,
    followerEvents: CalEvent[],
    trimSkippedEnds: boolean,
  ): void => {
    const leaderPlacement = computeLeaderPlacement(
      this,
      leaderEvent,
      drag,
      trimSkippedEnds,
    )

    this.editEventPlacement(
      leaderEvent,
      leaderPlacement.span,
      leaderPlacement.field,
      leaderPlacement.location,
      leaderPlacement.format,
    )

    for (const followerEvent of followerEvents) {
      const placement = computeFollowerPlacement(
        this,
        followerEvent,
        leaderEvent,
        leaderPlacement,
        drag,
        trimSkippedEnds,
      )

      this.editEventPlacement(
        followerEvent,
        placement.span,
        placement.field,
        placement.location,
        placement.format,
      )
    }
  }

  public applyEventCreationDrag = (
    drag: NonNullable<Drag>,
    isTempDrag: boolean,
  ): void => {
    const id = isTempDrag ? 'drag-temp' : uniqueId()

    const dragSpan = {
      startDate: drag.mouseDown.day,
      endDate: drag.mousePosition.day,
    }

    const { startDate } = boundSpan(dragSpan, 'endDate')
    const { endDate } = boundSpan(dragSpan, 'startDate')

    const firstRow = startDate.equals(endDate)
      ? Math.min(drag.mousePosition.rowIndex, drag.mouseDown.rowIndex)
      : drag.mouseDown.rowIndex
    const lastRow = startDate.equals(endDate)
      ? Math.max(drag.mousePosition.rowIndex, drag.mouseDown.rowIndex)
      : drag.mouseDown.rowIndex

    const properties: CalEventProperties = {
      id,
      label: '',
      categoryId: 'default',
      durationUnit: 'days',
      transparent: false,
      textAlign: 'center',
    }
    let span = {
      startDate,
      endDate,
    }

    let field: CalEventField

    const applicableSkips = new Set<CalEventSkip>()

    for (const skip of defaultSkips) {
      if (
        fieldDoesNotAffectSpan(this, span, {
          skips: new Set([skip]),
          adds: new Set(),
        })
      ) {
        applicableSkips.add(skip)
      }
    }

    field = {
      skips: applicableSkips,
      adds: new Set(),
    }

    if (!isTempDrag) {
      span = trimSpan(this, span, field)
    }
    const location = {
      firstRow,
      lastRow,
    }
    const format = defaultFormat()
    this.addEvent(properties, span, field, location, format)
    this.selectEvent(id, false)
  }

  public addDrag = (drag: NonNullable<Drag>, isTempDrag: boolean): void => {
    if (drag.draggedEvent) {
      const trimSkippedEnds = !isTempDrag

      if (drag.dragSide) {
        if (drag.dragSide === 'left' || drag.dragSide === 'right') {
          this.applyHorizontalResizeDrag(
            drag.draggedEvent,
            drag,
            drag.otherSelectedEvents ?? [],
            trimSkippedEnds,
          )
        } else {
          this.applyVerticalResizeDrag(
            drag.draggedEvent,
            drag,
            drag.otherSelectedEvents ?? [],
          )
        }
      } else {
        this.applyNonResizingMoveDrag(
          drag.draggedEvent,
          drag,
          drag.otherSelectedEvents ?? [],
          trimSkippedEnds,
        )
      }
    } else {
      this.applyEventCreationDrag(drag, isTempDrag)
    }
  }

  public addMarquee = (marquee: NonNullable<Marquee>): void => {
    const { modifySelection, eventIds } = marquee

    if (modifySelection) {
      eventIds.forEach((eventId) => {
        if (this.selectedEventIds.has(eventId)) {
          this.unselectEvent(eventId)
        } else {
          this.selectEvent(eventId, false, true)
        }
      })
    } else {
      this.unselectAllEvents()
      eventIds.forEach((eventId) => {
        this.selectEvent(eventId, false, true)
      })
    }
  }

  public getMarqueeEventIds = (marquee: NonNullable<Marquee>): string[] => {
    const startDay = marquee.topLeftLocation.day
    const endDay = marquee.bottomRightLocation.day
    const startRowIndex = adjustedRowIndex(marquee.topLeftLocation)
    const endRowIndex = adjustedRowIndex(marquee.bottomRightLocation)

    if (
      !marquee.topLeftLocation.isWithinCellX &&
      !marquee.bottomRightLocation.isWithinCellX &&
      marquee.topLeftLocation.dayIndex === marquee.bottomRightLocation.dayIndex
    ) {
      return []
    }

    const weekBooleans = weekStarting(this, startDay) & weekEnding(this, endDay)

    const marqueeEventIds = new Set<string>()
    const weekStartDates = this.listWeekStartDatesForSpan({
      startDate: startDay,
      endDate: endDay,
    })

    for (let weekIndex = 0; weekIndex < weekStartDates.length; weekIndex++) {
      const isFirstWeek = weekIndex === 0
      const isLastWeek = weekIndex === weekStartDates.length - 1
      const monthWeekStartsIn = YearMonth.from(weekStartDates[weekIndex])
      const monthWeekEndsIn = YearMonth.from(
        weekStartDates[weekIndex].plusDays(6),
      )
      const isRepeatedWeek =
        !monthWeekStartsIn.equals(monthWeekEndsIn) &&
        !marquee.topLeftLocation.pageMonth.isAfter(monthWeekStartsIn) &&
        !marquee.bottomRightLocation.pageMonth.isBefore(monthWeekEndsIn)

      const weekStartDate = weekStartDates[weekIndex]
      const weekId = weekStartDate.toString()

      const eventIds = this.weekIndex?.getEventIds(weekId) ?? []
      for (const id of eventIds) {
        const event = this.getEventById(id)
        if (!event || marqueeEventIds.has(event.id)) {
          continue
        }

        const eventWeek = event.displayBooleans.get(weekId) ?? 0

        if ((eventWeek & weekBooleans) === 0) {
          continue
        }

        const eventIsAboveMarquee = event.lastRow < startRowIndex
        const eventIsBelowMarquee = event.firstRow > endRowIndex

        if (
          (isRepeatedWeek &&
            isFirstWeek &&
            isLastWeek &&
            eventIsAboveMarquee &&
            eventIsBelowMarquee) ||
          (!isRepeatedWeek && isFirstWeek && eventIsAboveMarquee) ||
          (!isRepeatedWeek && isLastWeek && eventIsBelowMarquee)
        ) {
          continue
        }

        marqueeEventIds.add(event.id)
      }
    }
    return Array.from(marqueeEventIds)
  }

  public addEvent = (
    properties: CalEventProperties,
    span: CalEventSpan,
    field: CalEventField,
    location: CalEventLocation,
    format: CalEventFormat,
  ): CalEvent => {
    if (this.getEventById(properties.id)) {
      throw new Error(
        'CalData.addEvent() failed because event already exists with given id',
      )
    }
    const event: CalEvent = {
      ...properties,
      ...span,
      ...field,
      ...location,
      ...format,
      ...computeDurationAndMaps(this, span, field, format),
    }

    this.editEventAffectingOthers(event)
    return event
  }

  // Not an arrow function because we invoke this with `super`
  // in CalDataWithLiveblocks
  public editEventPlacement(
    event: CalEvent,
    newSpan?: CalEventSpan,
    newField?: CalEventField,
    newLocation?: CalEventLocation,
    newFormat?: CalEventFormat,
    newLabel?: string,
  ): void {
    let newEvent = {
      ...event,
      ...newSpan,
      ...newField,
      ...newLocation,
      ...(newLabel ? { label: newLabel } : {}),
      ...newFormat,
    }

    newEvent = {
      ...newEvent,
      ...computeDurationAndMaps(this, newEvent, newEvent, newEvent),
    }

    this.editEventAffectingOthers(newEvent)
  }

  // Called with `null` when an event has been deleted
  public editEventAffectingOthers = (
    eventWithChanges: CalEvent | null,
  ): void => {
    const unvisitedEvents = new Map()
    const allEvents = this.listEvents()
    for (const event of allEvents) {
      unvisitedEvents.set(event.id, event)
    }

    const cloneOrig = this.freezeOrClone()
    if (eventWithChanges) {
      cloneOrig.editEvent(eventWithChanges)
      unvisitedEvents.delete(eventWithChanges.id)
    }

    const dataWithTimeEffects = computeTimeEffects(cloneOrig, unvisitedEvents)

    if (dataWithTimeEffects === false) {
      console.error('No solution found for time effects')

      if (eventWithChanges) {
        this.editEvents([eventWithChanges])
      }
    } else {
      if (eventWithChanges) {
        const cloneWithTimeEffects = dataWithTimeEffects.clone()
        const originalEvent = this.getEventById(eventWithChanges.id)!
        if (originalEvent) {
          // Put back the original rows of the changed event
          // so that `assignLocations` can initialize its DAG correctly
          cloneWithTimeEffects.editEvent({
            ...eventWithChanges,
            firstRow: originalEvent.firstRow,
            lastRow: originalEvent.lastRow,
          })
        }
        const dataWithTimeAndLocationEffects = assignLocations(
          cloneWithTimeEffects,
          eventWithChanges,
        )
        if (dataWithTimeAndLocationEffects === false) {
          console.error('No solution found for location effects')
          const editedEvents = dataWithTimeEffects.listEditedEvents()
          this.editEvents(editedEvents)
        } else {
          const editedEvents = dataWithTimeAndLocationEffects.listEditedEvents()
          this.editEvents(editedEvents)
        }
      } else {
        const editedEvents = dataWithTimeEffects.listEditedEvents()
        this.editEvents(editedEvents)
      }
    }
  }

  public chooseInitialOnscreenMonth = (): YearMonth => {
    const events = this.listEvents()
    const nowMonth = YearMonth.from(LocalDate.now())

    if (events.length === 0) {
      return nowMonth
    }

    let firstMonth = YearMonth.from(events[0].startDate)
    let lastMonth = YearMonth.from(events[0].startDate)
    for (const event of events) {
      const month = YearMonth.from(event.startDate)

      if (month.equals(nowMonth)) {
        return nowMonth
      }

      if (month.isBefore(firstMonth)) {
        firstMonth = month
      }
      if (month.isAfter(lastMonth)) {
        lastMonth = month
      }
    }

    if (nowMonth.isBefore(firstMonth)) {
      return firstMonth
    }
    if (nowMonth.isAfter(lastMonth)) {
      return lastMonth
    }

    return nowMonth
  }
}

export default CalData
