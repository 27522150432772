import CalData from '../CalData'
import CalDataFrozen from '../CalDataFrozen'
import { CalEvent } from '../types'

class Graph<NodeData> {
  public nodes = new Map<string, NodeData>()
  public outflows = new Map<string, Set<string>>()
  public inflows = new Map<string, Set<string>>()

  public listNodeIds(): string[] {
    return Array.from(this.nodes.keys())
  }

  public addNode(id: string, data: NodeData): void {
    this.nodes.set(id, data)
    this.outflows.set(id, new Set())
    this.inflows.set(id, new Set())
  }

  public addEdge(fromId: string, toId: string): void {
    this.outflows.get(fromId)!.add(toId)
    this.inflows.get(toId)!.add(fromId)
  }

  public removeNode(id: string): void {
    for (const otherNode of this.outflows.get(id) ?? []) {
      this.inflows.get(otherNode)!.delete(id)
    }
    for (const otherNode of this.inflows.get(id) ?? []) {
      this.outflows.get(otherNode)!.delete(id)
    }
    this.outflows.delete(id)
    this.inflows.delete(id)
    this.nodes.delete(id)
  }

  public forEachEdge(
    callback: (
      fromId: string,
      fromData: NodeData,
      toId: string,
      toData: NodeData,
      breakLoop: () => void,
    ) => void,
  ): void {
    for (const [id, set] of this.outflows.entries()) {
      for (const otherNode of set) {
        let shouldBreak = false
        callback(
          id,
          this.nodes.get(id)!,
          otherNode,
          this.nodes.get(otherNode)!,
          () => {
            shouldBreak = true
          },
        )
        if (shouldBreak) {
          return
        }
      }
    }
  }

  public getSubgraph(nodeIds: Set<string>): this {
    const subgraph = Reflect.construct(this.constructor, [], this.constructor)
    for (const id of nodeIds) {
      subgraph.addNode(id, this.nodes.get(id)!)
    }
    for (const id of nodeIds) {
      for (const otherId of this.outflows.get(id)!) {
        if (nodeIds.has(otherId)) {
          subgraph.addEdge(id, otherId)
        }
      }
      for (const otherId of this.inflows.get(id)!) {
        if (nodeIds.has(otherId)) {
          subgraph.addEdge(otherId, id)
        }
      }
    }
    return subgraph
  }

  public getComponentContainingNode(nodeId: string): this {
    const visited = new Set<string>()

    const visit = (id: string, componentNodeIds: Set<string>): void => {
      if (visited.has(id)) {
        return
      }
      visited.add(id)
      componentNodeIds.add(id)
      for (const otherId of this.outflows.get(id)!) {
        visit(otherId, componentNodeIds)
      }
      for (const otherId of this.inflows.get(id)!) {
        visit(otherId, componentNodeIds)
      }
    }

    const componentNodeIds = new Set<string>()
    visit(nodeId, componentNodeIds)
    return this.getSubgraph(componentNodeIds)
  }

  // Unused for now; lists all weakly connected components
  public toWeaklyConnectedComponents(): this[] {
    const components: this[] = []
    const visited = new Set<string>()

    const visit = (id: string, componentNodeIds: Set<string>): void => {
      if (visited.has(id)) {
        return
      }
      visited.add(id)
      componentNodeIds.add(id)
      for (const otherId of this.outflows.get(id)!) {
        visit(otherId, componentNodeIds)
      }
      for (const otherId of this.inflows.get(id)!) {
        visit(otherId, componentNodeIds)
      }
    }

    for (const id of this.nodes.keys()) {
      if (visited.has(id)) {
        continue
      }
      const componentNodeIds = new Set<string>()
      visit(id, componentNodeIds)
      components.push(this.getSubgraph(componentNodeIds))
    }

    return components
  }
}

export class EventGraph extends Graph<{
  name: string
  firstRow: number
  lastRow: number
  displayBooleans: Map<string, number>
}> {
  public hasConflicts = (): boolean => {
    let doesHaveConflicts = false
    this.forEachEdge((fromId, fromData, toId, toData, breakLoop) => {
      if (fromData.lastRow >= toData.firstRow) {
        doesHaveConflicts = true
        breakLoop()
      }
    })
    return doesHaveConflicts
  }

  public print(): void {
    console.group('Graph:')
    for (const [id, set] of this.outflows.entries()) {
      const node = this.nodes.get(id)!

      for (const otherNodeId of set) {
        const otherNode = this.nodes.get(otherNodeId)!
        console.log(
          node.name,
          `(${node.firstRow}-${node.lastRow})`,
          '->',
          otherNode.name,
          `(${otherNode.firstRow}-${otherNode.lastRow})`,
        )
      }

      if (set.size === 0) {
        if (this.inflows.get(id)!.size === 0) {
          console.log(
            node.name,
            `(${node.firstRow}-${node.lastRow}) (no connections)`,
          )
        }
      }
    }
    console.groupEnd()
  }

  public getFirstRowBounds(rowCount: number): Map<string, [number, number]> {
    let inflowsVisited = new Map<string, number>()
    let outflowsVisited = new Map<string, number>()
    let nodesAbove = new Map<string, number>()
    let nodesBelow = new Map<string, number>()

    const aboveQueue: string[] = []
    const belowQueue: string[] = []

    for (const [id, set] of this.inflows.entries()) {
      inflowsVisited.set(id, set.size)
      if (set.size === 0) {
        // Above-Leaf node
        aboveQueue.push(id)
        nodesAbove.set(id, 0)
      }
    }
    for (const [id, set] of this.outflows.entries()) {
      outflowsVisited.set(id, set.size)
      if (set.size === 0) {
        // Below-Leaf node
        belowQueue.push(id)
        nodesBelow.set(id, 0)
      }
    }

    while (aboveQueue.length > 0) {
      const nodeId = aboveQueue.shift()!
      const node = this.nodes.get(nodeId)!
      for (const neighborId of this.outflows.get(nodeId)!) {
        inflowsVisited.set(neighborId, inflowsVisited.get(neighborId)! - 1)
        nodesAbove.set(
          neighborId,
          Math.max(
            nodesAbove.get(neighborId) ?? 0,
            (nodesAbove.get(nodeId) ?? 0) + (node.lastRow - node.firstRow) + 1,
          ),
        )
        if (inflowsVisited.get(neighborId)! === 0) {
          aboveQueue.push(neighborId)
        }
      }
    }

    while (belowQueue.length > 0) {
      const nodeId = belowQueue.shift()!
      const node = this.nodes.get(nodeId)!
      for (const neighborId of this.inflows.get(nodeId)!) {
        outflowsVisited.set(neighborId, outflowsVisited.get(neighborId)! - 1)
        nodesBelow.set(
          neighborId,
          Math.max(
            nodesBelow.get(neighborId) ?? 0,
            (nodesBelow.get(nodeId) ?? 0) + (node.lastRow - node.firstRow) + 1,
          ),
        )
        if (outflowsVisited.get(neighborId)! === 0) {
          belowQueue.push(neighborId)
        }
      }
    }

    const firstRowBounds = new Map<string, [number, number]>()
    for (const id of this.nodes.keys()) {
      const node = this.nodes.get(id)!
      const minFirstRow = Math.min(nodesAbove.get(id)!, rowCount - 1)
      const maxLastRow = Math.max(rowCount - 1 - nodesBelow.get(id)!, 0)
      const maxFirstRow = maxLastRow - (node.lastRow - node.firstRow)

      firstRowBounds.set(id, [minFirstRow, maxFirstRow])
    }
    return firstRowBounds
  }
}

export const computeGraph = (data: CalData): EventGraph => {
  const graph = new EventGraph()
  const allEvents = data.listEvents()
  const weekIds = new Set<string>()

  for (const event of allEvents) {
    graph.addNode(event.id, {
      name: event.label,
      firstRow: event.firstRow,
      lastRow: event.lastRow,
      displayBooleans: event.displayBooleans,
    })
    for (const weekId of event.displayBooleans.keys()) {
      weekIds.add(weekId)
    }
  }

  for (const weekId of weekIds) {
    const weekEvents = data.getWeekEvents(weekId)
    for (const event of weekEvents) {
      const booleans = event.displayBooleans.get(weekId)!

      for (const otherEvent of weekEvents) {
        if (event.id === otherEvent.id) {
          continue
        }
        const otherBooleans = otherEvent.displayBooleans.get(weekId)!
        if ((booleans & otherBooleans) === 0) {
          continue
        }

        if (otherEvent.firstRow > event.lastRow) {
          graph.addEdge(event.id, otherEvent.id)
        }
      }
    }
  }

  return graph
}

export const insertEventIntoGraph = (
  placement: 'above' | 'below',
  data: CalDataFrozen,
  graph: EventGraph,
  eventWithChanges: CalEvent,
): EventGraph => {
  graph.removeNode(eventWithChanges.id)
  graph.addNode(eventWithChanges.id, {
    name: eventWithChanges.label,
    firstRow: eventWithChanges.firstRow,
    lastRow: eventWithChanges.lastRow,
    displayBooleans: eventWithChanges.displayBooleans,
  })

  for (const [weekId, booleans] of eventWithChanges.displayBooleans) {
    const weekEvents = data.getWeekEvents(weekId)

    for (const otherEvent of weekEvents) {
      if (!graph.nodes.has(otherEvent.id)) {
        continue
      }
      if (eventWithChanges.id === otherEvent.id) {
        continue
      }
      const otherBooleans = otherEvent.displayBooleans.get(weekId)!
      if ((booleans & otherBooleans) === 0) {
        continue
      }

      const eventsOverlap = !(
        eventWithChanges.firstRow > otherEvent.lastRow ||
        otherEvent.firstRow > eventWithChanges.lastRow
      )
      const otherEventIsAbove = otherEvent.firstRow > eventWithChanges.lastRow
      const otherEventIsBelow = otherEvent.lastRow < eventWithChanges.firstRow

      if (placement === 'above' && (eventsOverlap || otherEventIsAbove)) {
        graph.addEdge(eventWithChanges.id, otherEvent.id)
      } else if (
        placement === 'below' &&
        (eventsOverlap || otherEventIsBelow)
      ) {
        graph.addEdge(otherEvent.id, eventWithChanges.id)
      }
    }
  }

  return graph
}
