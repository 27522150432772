import { ChronoUnit, LocalDate } from '@js-joda/core'

import CalData from '../CalData'

export const weekStarting = (
  data: CalData,
  date: LocalDate,
  weekStartDate?: LocalDate,
): number => {
  if (weekStartDate === undefined) {
    weekStartDate = data.weekOf(date)
  }

  const dayIndex = weekStartDate.until(date, ChronoUnit.DAYS)
  if (dayIndex < 0 || dayIndex > 6) {
    return 0b1111111
  }

  // offset = 0 => result = 1111111
  // offset = 1 => result = 0111111
  // ...
  // offset = 5 => result = 0000011
  // offset = 6 => result = 0000001
  return (1 << (7 - dayIndex)) - 1
}

export const weekEnding = (
  data: CalData,
  date: LocalDate,
  weekStartDate?: LocalDate,
): number => {
  if (weekStartDate === undefined) {
    weekStartDate = data.weekOf(date)
  }

  const dayIndex = weekStartDate.until(date, ChronoUnit.DAYS)
  if (dayIndex < 0 || dayIndex > 6) {
    return 0b1111111
  }

  // offset = 0 => result = 1000000
  // offset = 1 => result = 1100000
  // ...
  // offset = 5 => result = 1111110
  // offset = 6 => result = 1111111
  return ((1 << (dayIndex + 1)) - 1) << (6 - dayIndex)
}

export const countSetBits = (n: number): number => {
  let count = 0
  while (n) {
    n &= n - 1
    count++
  }
  return count
}

// For printing bitstrings while debugging
export const as7 = (bitstring: number): string => {
  return bitstring.toString(2).padStart(7, '0').slice(-7)
}
