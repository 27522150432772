import { ChronoUnit, LocalDate } from '@js-joda/core'

import CalData from '../CalData'
import {
  CalEvent,
  CalEventField,
  CalEventFormat,
  CalEventSkip,
  CalEventSpan,
  SkipType,
  TempCalEvent,
  WithNullables,
} from '../types'
import { computeDurationAndMaps } from './dates'

export const defaultSkips: CalEventSkip[] = ['weekends', 'holidays']

// TODO memoize this for each week
export const getUnskippedDaysForWeek = (
  data: CalData,
  weekStartDate: LocalDate,
  skips: Set<CalEventSkip>,
  adds: Set<CalEventSkip>,
): number => {
  let unskippedDays = 0b1111111
  for (const skip of skips) {
    if (skip === 'weekends') {
      const weekends = 0b1000001
      unskippedDays &= ~weekends
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(skip)) {
      const skipDate = LocalDate.parse(skip)
      const dayIndex = weekStartDate.until(skipDate, ChronoUnit.DAYS)
      if (dayIndex >= 0 && dayIndex < 7) {
        const day = 1 << (6 - dayIndex)
        unskippedDays &= ~day
      }
    } else if (skip === 'holidays') {
      // TODO create a holidays set
      for (const event of data.getWeekEvents(weekStartDate)) {
        if (event.categoryId === 'holiday') {
          const eventWeek = event.counterBooleans.get(weekStartDate.toString())
          if (eventWeek) {
            unskippedDays &= ~eventWeek
          }
        }
      }
    } else {
      const event = data.getEventById(skip)
      if (event) {
        const eventWeek = event.counterBooleans.get(weekStartDate.toString())
        if (eventWeek) {
          unskippedDays &= ~eventWeek
        }
      }
    }
  }

  for (const add of adds) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(add)) {
      const addDate = LocalDate.parse(add)
      const dayIndex = weekStartDate.until(addDate, ChronoUnit.DAYS)
      if (dayIndex >= 0 && dayIndex < 7) {
        const day = 1 << (6 - dayIndex)
        unskippedDays |= day
      }
    } else {
      const event = data.getEventById(add)
      if (event) {
        const eventWeek = event.counterBooleans.get(weekStartDate.toString())
        if (eventWeek) {
          unskippedDays |= eventWeek
        }
      }
    }
  }

  return unskippedDays
}

export const dateOrLatestEarlierUnskipped = (
  data: CalData,
  field: CalEventField,
  date: LocalDate,
): LocalDate => {
  let weekStartDate = data.weekOf(date)

  let earlierUnskippedDays = 0
  while (earlierUnskippedDays === 0) {
    const unskippedDays = getUnskippedDaysForWeek(
      data,
      weekStartDate,
      field.skips,
      field.adds,
    )
    const dayIndex = weekStartDate.until(date, ChronoUnit.DAYS)
    const day = 1 << (6 - dayIndex)
    if (day & unskippedDays) {
      return date
    }

    // This is similar to `weekEnding` but shifted by one day.
    // It is also the bitwise NOT of `weekStarting` in a 7-bit space.
    // dayIndex = 0 => (1111111 & earlierDays) = 0000000
    // dayIndex = 1 => (1111111 & earlierDays) = 1000000
    // ...
    // dayIndex = 5 => (1111111 & earlierDays) = 1111100
    // dayIndex = 6 => (1111111 & earlierDays) = 1111110
    const earlierDays = ~((1 << (7 - dayIndex)) - 1)
    earlierUnskippedDays = unskippedDays & earlierDays

    if (earlierUnskippedDays !== 0) {
      // (x & -x) isolates the rightmost set bit of x
      // Math.log2 gets that bit's right-index
      // (6 - i) returns the bit's left-index
      const latestUnskippedDayIndex =
        6 - Math.log2(earlierUnskippedDays & -earlierUnskippedDays)
      return weekStartDate.plusDays(latestUnskippedDayIndex)
    }

    weekStartDate = weekStartDate.minusDays(7)
    date = weekStartDate.plusDays(6)
  }

  return date
}

export const dateOrEarliestLaterUnskipped = (
  data: CalData,
  field: CalEventField,
  date: LocalDate,
): LocalDate => {
  let weekStartDate = data.weekOf(date)

  let laterUnskippedDays = 0
  while (laterUnskippedDays === 0) {
    const unskippedDays = getUnskippedDaysForWeek(
      data,
      weekStartDate,
      field.skips,
      field.adds,
    )
    const dayIndex = weekStartDate.until(date, ChronoUnit.DAYS)
    const day = 1 << (6 - dayIndex)
    if (day & unskippedDays) {
      return date
    }

    // This is similar to `weekStarting` but shifted by one day.
    // It is also the bitwise NOT of `weekEnding` in a 7-bit space.
    // dayIndex = 0 => laterDays = 0111111
    // dayIndex = 1 => laterDays = 0011111
    // ...
    // dayIndex = 5 => laterDays = 0000001
    // dayIndex = 6 => laterDays = 0000000
    const laterDays = day - 1
    laterUnskippedDays = unskippedDays & laterDays

    if (laterUnskippedDays !== 0) {
      // Math.floor(Math.log2(x)) gets the right-index of the leftmost set bit of x
      // (6 - i) returns that bit's left-index
      const earliestUnskippedDayIndex =
        6 - Math.floor(Math.log2(laterUnskippedDays))
      return weekStartDate.plusDays(earliestUnskippedDayIndex)
    }

    weekStartDate = weekStartDate.plusDays(7)
    date = weekStartDate
  }

  return date
}

export const maySkipAny = (
  event: CalEvent | TempCalEvent,
  skipType: SkipType,
): boolean => {
  if (skipType === 'weekends') {
    return true
  } else if (skipType === 'events') {
    return event.durationDays !== 1 && event.categoryId !== 'holiday'
  } else if (skipType === 'holidays') {
    return event.categoryId !== 'holiday'
  }
  return true
}

export const maySkipAll = (
  event: CalEvent | TempCalEvent,
  skipType: SkipType,
): boolean => {
  if (!maySkipAny(event, skipType)) return false

  if (skipType === 'weekends') {
    return true
  } else if (skipType === 'events') {
    return false
  } else if (skipType === 'holidays') {
    return event.categoryId !== 'holiday'
  }
  return true
}

export const listWeekendSkippables = (
  data: CalData,
  field: CalEventField,
  span: WithNullables<CalEventSpan, 'startDate' | 'endDate'>,
): Map<LocalDate, boolean> => {
  if (span.startDate === null || span.endDate === null) {
    return new Map()
  }

  const skippables: Map<LocalDate, boolean> = new Map()

  const weekendsSkippedByDefault = field.skips.has('weekends')

  let weekStartDate = data.weekOf(span.startDate)

  while (!weekStartDate.isAfter(span.endDate)) {
    for (const date of [weekStartDate, weekStartDate.plusDays(6)]) {
      if (!date.isBefore(span.startDate) && !date.isAfter(span.endDate)) {
        skippables.set(
          date,
          weekendsSkippedByDefault && !field.adds.has(date.toString()),
        )
      }
    }
    weekStartDate = weekStartDate.plusDays(7)
  }

  return skippables
}

export const listHolidaySkippables = (
  data: CalData,
  field: CalEventField,
  span: WithNullables<CalEventSpan, 'startDate' | 'endDate'>,
): Map<CalEvent, boolean> => {
  if (span.startDate === null || span.endDate === null) {
    return new Map()
  }

  const skippables: Map<CalEvent, boolean> = new Map()

  const holidaysSkippedByDefault = field.skips.has('holidays')

  let weekStartDate = data.weekOf(span.startDate)

  while (!weekStartDate.isAfter(span.endDate)) {
    for (const event of data.getWeekEvents(weekStartDate)) {
      if (event.categoryId === 'holiday') {
        if (
          !event.endDate.isBefore(span.startDate) &&
          !event.startDate.isAfter(span.endDate) &&
          !(
            event.startDate.isBefore(span.startDate) &&
            event.endDate.isAfter(span.endDate)
          )
        ) {
          skippables.set(
            event,
            holidaysSkippedByDefault && !field.adds.has(event.id),
          )
        }
      }
    }
    weekStartDate = weekStartDate.plusDays(7)
  }

  return skippables
}

export const listEventSkippables = (
  data: CalData,
  event: CalEvent | TempCalEvent,
): Map<CalEvent, boolean> => {
  if (event.startDate === null || event.endDate === null) {
    return new Map()
  }

  const skippables: Map<string, boolean> = new Map()
  const eventMap: Map<string, CalEvent> = new Map()

  let weekStartDate = data.weekOf(event.startDate)

  while (!weekStartDate.isAfter(event.endDate)) {
    for (const skippableEvent of data.getWeekEvents(weekStartDate)) {
      if (
        skippableEvent.id !== event.id &&
        skippableEvent.categoryId !== 'holiday'
      ) {
        if (
          !skippableEvent.endDate.isBefore(event.startDate) &&
          !skippableEvent.startDate.isAfter(event.endDate) &&
          !(
            skippableEvent.startDate.isBefore(event.startDate) &&
            skippableEvent.endDate.isAfter(event.endDate)
          )
        ) {
          eventMap.set(skippableEvent.id, skippableEvent)
          skippables.set(skippableEvent.id, event.skips.has(skippableEvent.id))
        }
      }
    }
    weekStartDate = weekStartDate.plusDays(7)
  }

  for (const add of event.skips) {
    const alreadySkippedEvent = data.getEventById(add)
    if (alreadySkippedEvent) {
      eventMap.set(alreadySkippedEvent.id, alreadySkippedEvent)
      skippables.set(alreadySkippedEvent.id, true)
    }
  }

  return Array.from(skippables.entries()).reduce((acc, [id, isSkipped]) => {
    const event = eventMap.get(id)
    if (event) {
      acc.set(event, isSkipped)
    }
    return acc
  }, new Map())
}

export const removeSkipsPreventingMoveToDate = (
  data: CalData,
  field: CalEventField,
  newDate: LocalDate,
  format: CalEventFormat,
): CalEventField => {
  let newSpan = {
    startDate: newDate,
    endDate: newDate,
  }
  const applicableSkips = new Set<CalEventSkip>()
  for (const skip of field.skips) {
    const { durationDays } = computeDurationAndMaps(
      data,
      newSpan,
      {
        ...field,
        skips: new Set([skip]),
      },
      format,
    )
    if (durationDays > 0) {
      applicableSkips.add(skip)
    }
  }
  return {
    ...field,
    skips: applicableSkips,
  }
}
