import { lazy, ReactElement, Suspense } from 'react'

import { HomePageRedirectProps } from './HomePageRedirect'

export const CalWithLiveblocksData = (): ReactElement => {
  const CalWithLiveblocksDataLazyLoaded = lazy(
    () => import('./CalWithLiveblocksData'),
  )
  return (
    <Suspense fallback={<></>}>
      <CalWithLiveblocksDataLazyLoaded />
    </Suspense>
  )
}

export const CalWithOfflineData = (): ReactElement => {
  const CalWithOfflineDataLazyLoaded = lazy(
    () => import('./CalWithOfflineData'),
  )
  return (
    <Suspense fallback={<></>}>
      <CalWithOfflineDataLazyLoaded />
    </Suspense>
  )
}

export const HomePageRedirect = (
  props: HomePageRedirectProps,
): ReactElement => {
  const HomePageRedirectLazyLoaded = lazy(() => import('./HomePageRedirect'))
  return (
    <Suspense fallback={<></>}>
      <HomePageRedirectLazyLoaded {...props} />
    </Suspense>
  )
}

export const AdminPage = (): ReactElement => {
  const AdminPageLazyLoaded = lazy(() => import('./AdminPage'))
  return (
    <Suspense fallback={<></>}>
      <AdminPageLazyLoaded />
    </Suspense>
  )
}
