import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { useEffect } from 'react'

import useStoreUserEffect from './convex/auth/useStoreUserEffect'

const AuthListener = (): null => {
  const { user, getAccessTokenSilently, logout } = useAuth0()
  const authUserExists = !!user

  const [, inviteToken] = window.location.search.split('token=') ?? []

  const { convexUserId, convexUserName } = useStoreUserEffect(inviteToken) ?? {
    convexUserId: null,
    convexUserName: null,
  }

  useEffect(() => {
    if (authUserExists && convexUserId) {
      posthog.identify(convexUserId, {
        convexUserId,
        email: user.email,
        name: convexUserName ?? user.name,
      })
      Sentry.setUser({ id: convexUserId, email: user.email })
    } else if (authUserExists) {
      Sentry.setUser({ email: user.email })
    } else {
      Sentry.setUser(null)
    }
  }, [
    getAccessTokenSilently,
    logout,
    user?.email,
    user?.name,
    authUserExists,
    convexUserId,
    convexUserName,
  ])

  return null
}

export default AuthListener
