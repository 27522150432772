import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { useConvexAuth } from 'convex/react'
import posthog from 'posthog-js'
import { Component, ErrorInfo, PropsWithChildren, ReactElement } from 'react'

import Button from './Button'
import useClearCodeEffect from './convex/auth/useClearCodeEffect'
import { loginLogoutOptions } from './defaults-auth'
import Wordmark from './icons/Wordmark'

const AuthGate = ({ children }: PropsWithChildren): ReactElement => {
  const { loginWithRedirect } = useAuth0()
  const { isLoading, isAuthenticated } = useConvexAuth()

  useClearCodeEffect()

  if (!isLoading && !isAuthenticated) {
    return (
      <div className="absolute inset-0 justify-center items-center gap-2">
        <Wordmark />
        <Button
          theme="white-border"
          label="Log In"
          onClick={() => {
            const loggedOutPath = sessionStorage.getItem('logged_out_path')
            loginWithRedirect({
              ...loginLogoutOptions,
              appState: {
                returnTo: loggedOutPath ?? window.location.pathname,
              },
            })
          }}
        />
      </div>
    )
  }

  return <AuthGateErrorBoundary>{children}</AuthGateErrorBoundary>
}

type AuthGateErrorBoundaryState = {
  hasError: boolean
}

class AuthGateErrorBoundary extends Component<
  PropsWithChildren,
  AuthGateErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(
    _error: Error,
  ): AuthGateErrorBoundaryState {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error(error, info.componentStack)
    Sentry.withScope((scope) => {
      scope.setExtras(info as any)
      Sentry.captureException(error)
    })
    posthog.capture('AuthGate error', {
      error: error.message,
      stack: info.componentStack,
    })
  }

  public render(): ReactElement {
    if (this.state.hasError) {
      return (
        <div className="absolute inset-0 justify-center items-center gap-2">
          <Wordmark />
          <div>Sorry, but you don&apos;t have access to this calendar.</div>
          <Button
            theme="white-border"
            label="Back to Dashboard"
            onClick={() => {
              window.location.href = '/'
            }}
          />
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}

export default AuthGate
