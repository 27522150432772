import CalDataFrozen from '../CalDataFrozen'
import { CalEvent } from '../types'
import { computeChanges } from './changes'
import {
  changeFieldPreservingDurationDays,
  computeDurationAndMaps,
} from './dates'

export const computeTimeEffects = (
  data: CalDataFrozen,
  unvisitedEvents: Map<string, CalEvent>,
): CalDataFrozen | false => {
  const affectedEvents = []
  for (const event of unvisitedEvents.values()) {
    const affectedEvent = recomputeEventInField(data, event)
    if (affectedEvent === false) {
      return false
    }
    if (affectedEvent !== null) {
      affectedEvents.push(affectedEvent)
    }
  }

  if (affectedEvents.length === 0) {
    return data
  }

  // Choose one of the affectedEvents and recurse depth-first
  // If recursion returns false (failure), try again with a different affectedEvent
  // If all affectedEvents have been tried, return false (failure)
  //
  // A possible optimization here would be to order the affectedEvents optimally
  for (const affectedEvent of affectedEvents) {
    let unvisitedClone = new Map(unvisitedEvents)
    unvisitedClone.delete(affectedEvent.id)

    const dataClone = data.clone()
    dataClone.editEvent(affectedEvent)

    const result = computeTimeEffects(dataClone, unvisitedClone)
    if (result === false) {
      // Try again with a different affectedEvent
    } else {
      // Success
      return result
    }
  }

  // Failure
  return false
}

// Return null if the event is unchanged
// Return false if the event cannot be recomputed
// Return the new event if the event was recomputed
export const recomputeEventInField = (
  data: CalDataFrozen,
  event: CalEvent,
): CalEvent | false | null => {
  // Compute new position on the time axis,
  // because upstream events may have affected skips
  let newEvent = {
    ...event,
    ...changeFieldPreservingDurationDays(data, event, event, event),
  }
  newEvent = {
    ...newEvent,
    ...computeDurationAndMaps(data, newEvent, newEvent, newEvent),
  }

  const changes = computeChanges(event, newEvent)
  if (Object.keys(changes).length === 0) {
    return null
  }

  return newEvent
}
