import { PropsWithChildren, ReactElement, useContext } from 'react'

import CalLoading from './CalLoading'
import { Id } from './convex/_generated/dataModel'
import { RoomProvider } from './liveblocks.config'
import VersionProvider, { VersionContext } from './VersionProvider'

const LiveblocksGateInner = ({ children }: PropsWithChildren): ReactElement => {
  const { activeVersionId } = useContext(VersionContext)
  if (activeVersionId) {
    return (
      <RoomProvider id={activeVersionId} initialPresence={{}}>
        {children}
      </RoomProvider>
    )
  } else {
    return <CalLoading />
  }
}

const LiveblocksGate = ({
  calId,
  calVersionId,
  children,
}: PropsWithChildren<{
  calId: string
  calVersionId?: string
}>): ReactElement => {
  return (
    <VersionProvider
      calId={calId as Id<'cals'>}
      calVersionId={calVersionId as Id<'calVersions'> | undefined}
    >
      <LiveblocksGateInner>{children}</LiveblocksGateInner>
    </VersionProvider>
  )
}

export default LiveblocksGate
