import './PostHog'

import { Auth0Provider } from '@auth0/auth0-react'
import { ConvexReactClient } from 'convex/react'
import { ReactElement } from 'react'

import AuthGate from './AuthGate'
import AuthListener from './AuthListener'
import { ConvexProvider } from './ConvexProvider'
import {
  AdminPage,
  CalWithLiveblocksData,
  CalWithOfflineData,
  HomePageRedirect,
} from './Lazy'
import LiveblocksGate from './LiveblocksGate'
import { NavProviderBrowser } from './NavProvider'
import SentryGate from './SentryGate'

const onRedirectCallback = (appState?: {
  returnTo?: string
  inviteToken?: string
}): void => {
  let path = '/'
  if (appState?.returnTo) {
    path = appState.returnTo
  }
  if (appState?.inviteToken) {
    path += `?token=${appState.inviteToken}`
  }
  window.location.href = path
}

const authorizationParams = {
  redirect_uri: window.location.origin,
}

const App = (): ReactElement => {
  const convex = new ConvexReactClient(
    process.env.REACT_APP_CONVEX_URL as string,
  )

  const regexResult = /.*\/calendar\/(\w*)(?:\/(\w*))?/g.exec(
    window.location.href,
  )
  const [, calId, calVersionId] = regexResult || []

  const isAdminRoute = window.location.pathname === '/admin'
  const isLoginRoute = window.location.pathname === '/login'
  const isOfflineRoute =
    window.location.pathname === '/offline' &&
    process.env.NODE_ENV === 'development'
  const isHomeRoute = window.location.pathname === '/home'

  if (isOfflineRoute) {
    return <CalWithOfflineData />
  }

  if (isHomeRoute) {
    return (
      <ConvexProvider client={convex}>
        <HomePageRedirect forceMarketingPage />
      </ConvexProvider>
    )
  }

  return (
    <SentryGate>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        authorizationParams={authorizationParams}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <ConvexProvider client={convex}>
          <NavProviderBrowser>
            <AuthListener />
            {isLoginRoute ? (
              <AuthGate />
            ) : calId ? (
              <AuthGate>
                <LiveblocksGate calId={calId} calVersionId={calVersionId}>
                  <CalWithLiveblocksData />
                </LiveblocksGate>
              </AuthGate>
            ) : isAdminRoute ? (
              <AuthGate>
                <AdminPage />
              </AuthGate>
            ) : (
              <HomePageRedirect />
            )}
          </NavProviderBrowser>
        </ConvexProvider>
      </Auth0Provider>
    </SentryGate>
  )
}

export default App
