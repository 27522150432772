import CalData from '../CalData'
import CalDataFrozen from '../CalDataFrozen'
import {
  CalEvent,
  CalEventLocation,
  CellLocation,
  DetailedLocation,
} from '../types'
import { computeGraph, EventGraph, insertEventIntoGraph } from './graphs'

export const findFreeLocation = (
  data: CalData,
  event: CalEvent,
): CalEventLocation => {
  const dataClone = data.freezeOrClone()
  const graph = computeGraph(dataClone)
  const rowCount = data.getWeekRowCount('')
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const newLocation = {
      firstRow: rowIndex,
      lastRow: rowIndex,
    }
    const newGraph = insertEventIntoGraph('below', dataClone, graph, {
      ...event,
      ...newLocation,
    })
    if (!newGraph.hasConflicts()) {
      return newLocation
    }
  }

  return {
    firstRow: 0,
    lastRow: 0,
  }
}

export const assignLocations = (
  dataOld: CalDataFrozen,
  eventWithChanges: CalEvent,
): CalDataFrozen | false => {
  const eventOld = dataOld.getEventById(eventWithChanges.id)

  const graphOld = computeGraph(dataOld)

  const dataWithEventChanged = dataOld.clone()
  dataWithEventChanged.editEvent(eventWithChanges)

  const insertAbove = (): CalDataFrozen | false => {
    const graphAbove = insertEventIntoGraph(
      'above',
      dataOld,
      graphOld,
      eventWithChanges,
    )
    const subgraphAbove = graphAbove.getComponentContainingNode(
      eventWithChanges.id,
    )
    if (!subgraphAbove.hasConflicts()) {
      return dataWithEventChanged
    }
    const resultAbove = resolveConflicts(
      dataWithEventChanged,
      subgraphAbove,
      eventWithChanges,
      'downward',
    )
    return resultAbove
  }

  const insertBelow = (): CalDataFrozen | false => {
    const graphBelow = insertEventIntoGraph(
      'below',
      dataOld,
      graphOld,
      eventWithChanges,
    )
    const subgraphBelow = graphBelow.getComponentContainingNode(
      eventWithChanges.id,
    )
    if (!subgraphBelow.hasConflicts()) {
      return dataWithEventChanged
    }
    const resultBelow = resolveConflicts(
      dataWithEventChanged,
      subgraphBelow,
      eventWithChanges,
      'upward',
    )
    return resultBelow
  }

  let preferredDirection = 'above'
  if (eventOld && eventWithChanges.firstRow > eventOld.firstRow) {
    preferredDirection = 'below'
  }

  if (preferredDirection === 'above') {
    const resultAbove = insertAbove()
    if (resultAbove !== false) {
      return resultAbove
    }
    const resultBelow = insertBelow()
    if (resultBelow !== false) {
      return resultBelow
    }
  } else {
    const resultBelow = insertBelow()
    if (resultBelow !== false) {
      return resultBelow
    }
    const resultAbove = insertAbove()
    if (resultAbove !== false) {
      return resultAbove
    }
  }

  return false
}

const resolveConflicts = (
  data: CalDataFrozen,
  subgraph: EventGraph,
  eventWithChanges: CalEvent,
  preferredDirection: 'upward' | 'downward',
): CalDataFrozen | false => {
  const rowCount = data.getWeekRowCount('')
  const firstRowBounds = subgraph.getFirstRowBounds(rowCount)

  let newData = data.clone()

  const unvisitedEvents = new Map<string, CalEvent>()
  for (const eventId of subgraph.listNodeIds()) {
    const event = newData.getEventById(eventId)!
    unvisitedEvents.set(eventId, event)
  }

  // Ignore any events that were already conflicting before this change
  for (const event of unvisitedEvents.values()) {
    if (event.id === eventWithChanges.id) {
      continue
    }
    if (locationDoesConflict(data, event, unvisitedEvents)) {
      unvisitedEvents.delete(event.id)
    }
  }

  const eventWithChangesFirstRowBounds = firstRowBounds.get(
    eventWithChanges.id,
  )!
  if (
    eventWithChanges.firstRow >= eventWithChangesFirstRowBounds[0] &&
    eventWithChanges.firstRow <= eventWithChangesFirstRowBounds[1]
  ) {
    newData.editEvent(eventWithChanges)
    unvisitedEvents.delete(eventWithChanges.id)
  } else {
    return false
  }

  // Fix any events that have only one possible location
  // Return false if any events have no possible location
  for (const [id, [minFirstRow, maxFirstRow]] of firstRowBounds) {
    if (!unvisitedEvents.has(id)) {
      continue
    }
    if (minFirstRow > maxFirstRow) {
      return false
    }
    if (minFirstRow === maxFirstRow) {
      const event = unvisitedEvents.get(id)!
      const rowDifference = event.lastRow - event.firstRow
      event.firstRow = minFirstRow
      event.lastRow = minFirstRow + rowDifference
      if (locationDoesConflict(newData, event, unvisitedEvents)) {
        return false
      }
      newData.editEvent(event)
      unvisitedEvents.delete(id)
    }
  }

  if (unvisitedEvents.size === 0) {
    return newData
  }

  for (const event of unvisitedEvents.values()) {
    const result = resolveConflictsForEvent(
      newData,
      firstRowBounds,
      unvisitedEvents,
      event,
      preferredDirection,
    )
    if (result !== false) {
      return result
    }
  }

  return false
}

const resolveConflictsForEvent = (
  data: CalDataFrozen,
  firstRowBounds: Map<string, [number, number]>,
  unvisitedEvents: Map<string, CalEvent>,
  eventToResolve: CalEvent,
  preferredDirection: 'upward' | 'downward',
): CalDataFrozen | false => {
  const [minFirstRow, maxFirstRow] = firstRowBounds.get(eventToResolve.id)!
  const origFirstRow = eventToResolve.firstRow

  const possibleFirstRows = computeFirstRowPreferenceOrder(
    minFirstRow,
    maxFirstRow,
    origFirstRow,
    preferredDirection,
  )

  for (const firstRow of possibleFirstRows) {
    const lastRow =
      firstRow + (eventToResolve.lastRow - eventToResolve.firstRow)
    const event = {
      ...eventToResolve,
      firstRow,
      lastRow,
    }

    if (locationDoesConflict(data, event, unvisitedEvents)) {
      continue
    }

    const dataClone = data.clone()
    dataClone.editEvent(event)

    const unvisitedEventsClone = new Map(unvisitedEvents)
    unvisitedEventsClone.delete(event.id)
    const nextEvent = unvisitedEventsClone.values().next().value
    if (!nextEvent) {
      return dataClone
    }
    const result = resolveConflictsForEvent(
      dataClone,
      firstRowBounds,
      unvisitedEventsClone,
      nextEvent,
      preferredDirection,
    )
    if (result !== false) {
      return result
    }
  }

  return false
}

export const computeFirstRowPreferenceOrder = (
  minFirstRow: number,
  maxFirstRow: number,
  origFirstRow: number,
  preferredDirection: 'upward' | 'downward',
): number[] => {
  if (origFirstRow < minFirstRow) {
    const rows = []
    for (let row = minFirstRow; row <= maxFirstRow; row++) {
      rows.push(row)
    }
    return rows
  }

  if (origFirstRow > maxFirstRow) {
    const rows = []
    for (let row = maxFirstRow; row >= minFirstRow; row--) {
      rows.push(row)
    }
    return rows
  }

  const rowSet = new Set()
  for (let row = minFirstRow; row <= maxFirstRow; row++) {
    rowSet.add(row)
  }

  const rows = []
  let offset = 0
  while (rowSet.size > 0) {
    if (preferredDirection === 'upward') {
      const rowAbove = origFirstRow - offset
      if (rowSet.has(rowAbove)) {
        rows.push(rowAbove)
        rowSet.delete(rowAbove)
      }
      const rowBelow = origFirstRow + offset
      if (rowSet.has(rowBelow)) {
        rows.push(rowBelow)
        rowSet.delete(rowBelow)
      }
    } else {
      const rowBelow = origFirstRow + offset
      if (rowSet.has(rowBelow)) {
        rows.push(rowBelow)
        rowSet.delete(rowBelow)
      }
      const rowAbove = origFirstRow - offset
      if (rowSet.has(rowAbove)) {
        rows.push(rowAbove)
        rowSet.delete(rowAbove)
      }
    }

    offset++
  }
  return rows
}

export const locationDoesConflict = (
  data: CalData,
  event: CalEvent,
  unvisitedEvents: Map<string, CalEvent>,
): boolean => {
  for (const [weekId, eventBooleans] of event.displayBooleans) {
    const otherEvents = data.getWeekEvents(weekId)
    for (const otherEvent of otherEvents) {
      if (event.id === otherEvent.id) {
        continue
      }

      if (unvisitedEvents.has(otherEvent.id)) {
        // We don't know where otherEvent will end up,
        // so we can't check for conflicts with it yet
        continue
      }

      if (
        event.firstRow > otherEvent.lastRow ||
        otherEvent.firstRow > event.lastRow
      ) {
        // The rows don't conflict, so no need to check for date conflicts
        continue
      }

      // Check for date conflicts
      const otherBooleans = otherEvent.displayBooleans.get(weekId) || 0
      if (eventBooleans & otherBooleans) {
        return true
      }
    }
  }

  return false
}

export const locationsExistAndAreIdentical = (
  loc1: CellLocation,
  loc2: CellLocation,
): boolean => {
  if (!loc1 || !loc2) return false

  return (
    loc1.pageIndex === loc2.pageIndex &&
    loc1.weekIndex === loc2.weekIndex &&
    loc1.dayIndex === loc2.dayIndex &&
    loc1.rowIndex === loc2.rowIndex
  )
}

export const adjustedRowIndex = (location: DetailedLocation): number => {
  if (!location.isWithinCellY) {
    return location.rowIndex === 0 ? -Infinity : Infinity
  }
  return location.rowIndex
}
