import CalData from './CalData'
import { DataModel } from './convex/_generated/dataModel'
import {
  defaultDefaultCategory,
  defaultHolidayCategory,
  emptyCalMetadata,
  initialCalMetadata,
} from './defaults-cal'
import { deserializeCalEvent, serializeCalEvent } from './math/serialization'
import { CalCategory, CalEvent, CalMetadata, Serialized } from './types'
import WeekIndex from './WeekIndex'

class CalDataFrozen extends CalData {
  constructor(
    private events: Map<string, CalEvent>,
    private metadata: Map<keyof CalMetadata, string>,
    private categories: Map<string, CalCategory>,
    protected weekIndex: WeekIndex,
    private editedEventIds = new Set<string>(),
  ) {
    super()
  }

  public static create = (): CalDataFrozen => {
    const data = new CalDataFrozen(
      new Map(),
      new Map(),
      new Map(),
      new WeekIndex([]),
    )
    data.setDefaults()
    return data
  }

  public setDefaults = (): void => {
    this.metadata = new Map(
      Object.entries({
        ...emptyCalMetadata,
        ...initialCalMetadata,
      }) as [keyof CalMetadata, string][],
    )
    this.categories = new Map([
      [defaultDefaultCategory.id, defaultDefaultCategory],
      [defaultHolidayCategory.id, defaultHolidayCategory],
    ])
  }

  public toLiveblocksJson = (): string => {
    const serializedEvents: { [eventId: string]: Serialized<CalEvent> } = {}
    for (const [eventId, event] of this.events) {
      serializedEvents[eventId] = serializeCalEvent(event)
    }

    return JSON.stringify({
      liveblocksType: 'LiveObject',
      data: {
        events: {
          liveblocksType: 'LiveMap',
          data: serializedEvents,
        },
        metadata: {
          liveblocksType: 'LiveMap',
          data: Object.fromEntries(this.metadata.entries()),
        },
        categories: {
          liveblocksType: 'LiveMap',
          data: Object.fromEntries(this.categories.entries()),
        },
      },
    })
  }

  public static fromLiveblocksJson = (jso: any): CalDataFrozen => {
    const { data: liveblocksData } = jso
    const { data: categoriesObj } = liveblocksData.categories
    const { data: metadataObj } = liveblocksData.metadata
    const { data: eventsObj } = liveblocksData.events

    const events = new Map()
    for (const [eventId, serializedEvent] of Object.entries(eventsObj)) {
      const event = deserializeCalEvent(serializedEvent as Serialized<CalEvent>)
      events.set(eventId, event)
    }

    const metadata = new Map(
      Object.entries(metadataObj) as [keyof CalMetadata, string][],
    )

    const categories = new Map()
    for (const category of Object.values(categoriesObj) as CalCategory[]) {
      categories.set(category.id, category)
    }

    const weekIndex = new WeekIndex(Array.from(events.values()))

    return new CalDataFrozen(events, metadata, categories, weekIndex)
  }

  public toConvexData = (): DataModel['calVersions']['document']['data'] => {
    const serializedEvents: { [eventId: string]: Serialized<CalEvent> } = {}
    for (const [eventId, event] of this.events) {
      serializedEvents[eventId] = serializeCalEvent(event)
    }

    return {
      events: serializedEvents,
      metadata: Object.fromEntries(this.metadata.entries()) as CalMetadata,
      categories: Array.from(this.categories.values()),
    }
  }

  public static fromConvexData = (
    convexData: NonNullable<DataModel['calVersions']['document']['data']>,
  ): CalDataFrozen => {
    const events = new Map()
    for (const [eventId, serializedEvent] of Object.entries(
      convexData.events,
    )) {
      const event = deserializeCalEvent(serializedEvent as Serialized<CalEvent>)
      events.set(eventId, event)
    }

    const metadata = new Map(
      Object.entries(convexData.metadata) as [keyof CalMetadata, string][],
    )

    const categories = new Map()
    for (const category of convexData.categories.values()) {
      categories.set(category.id, category)
    }

    const weekIndex = new WeekIndex(Array.from(events.values()))

    return new CalDataFrozen(events, metadata, categories, weekIndex)
  }

  public listEvents = (): CalEvent[] => {
    return Array.from(this.events.values())
  }

  public getEventById = (id: string | null): CalEvent | undefined => {
    if (!id) return undefined

    return this.events.get(id)
  }

  public getCategoryById = (categoryId: string): CalCategory => {
    return this.categories.get(categoryId) ?? this.getDefaultCategory()
  }

  public getDefaultCategory = (): CalCategory => {
    return this.categories.get('default')!
  }

  public selectEvent = (
    id: string,
    scrollTo?: boolean,
    asMulti?: boolean,
  ): void => {
    if (asMulti) {
      this.treatSelectionAsMulti = true
    } else {
      this.treatSelectionAsMulti = false
    }
    this.selectedEventIds.add(id)
  }

  public selectEventOnly = (id: string): void => {
    this.selectedEventIds.clear()
    this.selectedEventIds.add(id)
  }

  public unselectEvent = (id: string): void => {
    this.selectedEventIds.delete(id)
  }

  public unselectAllEvents = (): void => {
    this.selectedEventIds.clear()
  }

  public deleteEvent = (eventId: string): void => {
    const event = this.events.get(eventId)
    if (event) {
      this.events.delete(eventId)
      this.weekIndex?.removeEvent(event)
    }
  }

  public editEvents = (changedEvents: CalEvent[]): void => {
    for (const event of changedEvents) {
      this.events.set(event.id, event)
      this.weekIndex?.updateEvent(event, event)
      this.editedEventIds.add(event.id)
    }
  }

  public clone = (): CalDataFrozen => {
    const data = new CalDataFrozen(
      new Map(this.events),
      new Map(this.metadata),
      new Map(this.categories),
      this.weekIndex.clone(),
      new Set(this.editedEventIds),
    )
    data.selectedEventIds = new Set(this.selectedEventIds)
    data.treatSelectionAsMulti = this.treatSelectionAsMulti
    return data
  }

  public freezeOrClone = (): CalDataFrozen => {
    return this.clone()
  }

  public editEvent = (eventWithChanges: CalEvent): void => {
    const oldEvent = this.events.get(eventWithChanges.id)

    this.events.set(eventWithChanges.id, eventWithChanges)
    if (oldEvent) {
      this.weekIndex?.updateEvent(oldEvent, eventWithChanges)
    } else {
      this.weekIndex?.addEvent(eventWithChanges)
    }

    this.editedEventIds.add(eventWithChanges.id)
  }

  public listEditedEvents = (): CalEvent[] => {
    const events: CalEvent[] = []
    for (const id of this.editedEventIds) {
      const event = this.events.get(id)
      if (event) {
        events.push(event)
      }
    }
    return events
  }

  public getMetadata = (key: keyof CalMetadata): string | undefined => {
    return this.metadata?.get(key)
  }

  public editMetadata = (key: keyof CalMetadata) => (newValue: string) => {
    this.metadata?.set(key, newValue)
  }
}

export default CalDataFrozen
